@import "../base/mixins";

.price-list__preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #00FFFF;
  @include paddingTop64();
  @include paddingBottom64();
  @include paddingLeft48();
  @include paddingRight48();
  text-decoration: none !important;

  @include at-xs{
    height: 300px;
  }

  @include at-fh{
    height: 437px;
  }
}

.price-list__preview--more-type {
  border: 1px dashed white;

  .price-list-preview__price-description {
    color: white;
    @include font18();
    @include marginBottom32();
  }

  .price-list-preview__price {
    color: white;
    margin-bottom: 0;
  }
}

.price-list-preview__top-block {
  @include font18();
  text-transform: uppercase;
  color: #00FFFF;
}

.price-list-preview__bottom-block {

}

.price-list-preview__price {
  font-weight: bold;
  @include font48();
  color: #00FFFF;
  @include marginBottom32();
}


.price-list-preview__price-description {
  @include font16();
  text-transform: uppercase;
  color: #00FFFF;
}

.price-list__preview--small {
  height: 275px;

  .price-list-preview__price {
    margin-bottom: 0;
  }

  .price-list-preview__top-block {
    @include marginBottom16();
  }
}

a.price-list__preview--small{
  &:hover{
    background: white;

    .price-list-preview__price {
      color: #160F66;
    }

    .price-list-preview__top-block {
      color: #160F66;
    }
  }
}

.price-list__preview-small-description-block{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include at-sm{
    height: 275px;
  }
}

.price-list__preview-small-description{
  display: grid;
  grid-gap: 40px;

  @include at-xs{
    grid-template-columns: 1fr;
  }

  @include at-sm{
    grid-template-columns: 1fr 2fr;
  }
}

.price-list__preview-small-description-icon{
  text-align: right;

  @include at-xs{
    display: none;
  }

  @include at-sm{
    display: block;
  }
}

.price-list__preview-small-description-text{
  @include font16();
  color: #00FFFF;
}