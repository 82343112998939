@import "../base/mixins";

.not-found-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.not-found-page__bg {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  svg {
    width: 100%;
    height: auto;
  }
}

.not-found-page__content-wrapper {
  width: 100%;
  height: 100%;
}

.not-found-page__content-text {
  position: relative;
  display: grid;
  height: 100vh;

  @include at-md {
    grid-template-columns: 5fr 4fr 3fr;
  }

  @include at-lg {
    grid-template-columns: 7fr 2fr 3fr;
  }
}

.not-found-page__content {
  position: relative;
  height: 100%;
}

.not-found-page__content-inner {
  position: absolute;
  bottom: 50%;

  @include at-lg{
    bottom: 25%;
  }

  @include at-fh{
    bottom: 50%;
  }
}

.not-found-page__content-tag {
  font-size: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  @include marginBottom16();
}

.not-found-page__content-title, .not-found-page__content-description {
  @include font32();
  color: #00FFFF;
}

.not-found-page__content-title {
  font-weight: bold;
}

.not-found-page__content-description {
  a{
    color: #00FFFF!important;
  }
}
