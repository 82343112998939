@import '../base/mixins';

.arena-title-block {
  //@include marginTop512();
  @include marginBottom128();

  @include at-xs {
    background-image: url(../../images/main_background.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }

  @include at-sm {
    background-image: none;
  }
}

.arena-title-block__top-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include at-xs {
    justify-content: center;
  }

  @include at-sm {
    justify-content: flex-end;
  }
}

.arena-title-block__top {
  //@include marginBottom256();
  @include paddingBottom128();
  display: grid;
  grid-template-columns: 5fr 1fr 6fr;
  grid-gap: 40px;


  @include at-xs {
    .empty {
      display: none;
    }

    grid-template-columns: 1fr;
  }

  @include at-sm {
    grid-template-columns: 6fr 6fr;
  }

  @include at-md {
    .empty {
      display: block;
    }

    grid-template-columns: 5fr 1fr 6fr;
  }
}

.arena-title-block__bottom {
  display: grid;
  grid-template-columns: 5fr 1fr 3fr 3fr;
  grid-gap: 40px;

  @include at-xs {
    margin-top: 50px;
    .empty {
      display: none;
    }

    grid-template-columns: 1fr;
  }

  @include at-sm {
    margin-top: 127px;
    grid-template-columns: 6fr 6fr;
  }

  @include at-fh {
    .empty {
      display: block;
    }

    grid-template-columns: 5fr 1fr 3fr 3fr;
  }
}

.arena-title-block__description {
  font-style: normal;
  font-weight: 200;
  @include font48();
  color: #FFFFFF;
  position: relative;
  z-index: 1;

  @include at-xs {
    display: none;
  }

  @include at-sm {
    display: block;
  }

  span {
    color: #00FFFF;
  }
}

.arena-title-block__small-description {
  font-weight: 200;
  @include font32();
  color: #FFFFFF;
  position: relative;
  z-index: 1;
}

.arena-title-block__button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;

  @include at-sm {
    display: none;
  }

  .base-button__small-blue{
    width: 275px;
    svg {
      margin-right: 16px;
    }
  }
}