@import "../base/mixins";

.burger-button__wrapper{
  @include marginLeft32();
}

.burger-button{
  width: 20px;
  height: 26px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &:hover{
    .burger-button-line{
      background: white;
      width: 5px;
    }
  }
}

.burger-button__group-lines{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.burger-button-line{
  background: #00FFFF;
  width: 8px;
  height: 2px;
  transition: 0.1s linear all;
}