@import '../base/mixins';

.contacts-page{
  @include marginTop128();
  @include paddingTop48();

  @include at-lg{
    padding-top: 0;
  }

  .main-map{
    @include at-sm {
      height: 550px;
      min-height: calc(100vh - 496px);
      display: block;
    }

    @include at-md {
      height: 100vh;
      min-width: 750px;
    }

    @include at-fh {
      height: 100vh;
      min-height: 1020px;
      display: block;
    }
  }
}