@import "../base/mixins";

.events-slider{
  position: relative;
  width: 100%;
  height: 100vh;
}

.events-slider__navigation-wrapper{
  position: absolute;

  display: flex;

  @include at-xs{
    bottom: 50px;
    left: 32px;
  }

  @include at-lg{
    bottom: 270px;
    right: 40px;
    left: auto;
  }

  .link-with-arrow{
    @include marginLeft32();
  }
}


.events-slider__navigation{
  width: 127px;
  height: 64px!important;
  border: none!important;
  border-right: 0!important;
  border-left: 0!important;
  color: #00FFFF;
  line-height: 21px;
  padding: 0 16px!important;
  margin: 0!important;
  cursor: default!important;

  .base-button-transparent--decoration-right, .base-button-transparent--decoration-left  {
    &:before, &:after {
      border-color: #00FFFF;
    }
  }

  .base-button-transparent--decoration-right:before{
    right: -13.5px;
  }

  .base-button-transparent--decoration-right:after{
    height: 22.5%;
    transform: skew(-43.2deg, 0deg);
  }

  .base-button-transparent--decoration-left:before{
    left: -4.5px;
    transform: skew(-17deg, 0deg);
  }

  .base-button-transparent--decoration-left:after{
    transform: skew(5deg, 0deg);
  }

  svg path{
    fill: #00FFFF;
  }
}


.events-slider__navigation-decoration{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  svg path{
    fill: none;
  }
}

.events-slider__navigation-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.events-slider__navigation-back-button, .events-slider__navigation-forward-button{
  background: transparent!important;
  padding: 0;
  box-shadow: none!important;
  border: none!important;
  cursor: pointer;
  outline: none!important;
  height: 100%;
  width: 28px;

  &:hover{
    svg path{
      fill: white;
    }
  }
}

.events-slider__navigation-counter{
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.680556px;
  color: #00FFFF;
  margin: auto;
}

.main-event-slide__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: white;

  &:hover{
    color: #00FFFF;
  }
}