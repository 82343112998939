@import "../base/mixins";

.base-link{
  @include font16();
  color: #00FFFF;
  text-decoration: none!important;
  border-bottom: 1px solid rgba(0,255,255,0.2);
  text-transform: uppercase;
  @include paddingBottom8();

  &:hover{
    color: white;
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }

  &--other{
    border-bottom: 1px solid rgba(0, 255, 255, 0.2);
    @include paddingBottom8();
  }

  &--other-dashed{
    border-bottom: 1px dashed rgba(0, 255, 255, 0.3);
  }
}