@import "../base/mixins";

.main-commands-block{
  position: relative;
  min-height: 920px;

  @include at-xs{
    margin-top: 50px;
  }

  @include at-lg{
    margin-top: 127px;
    height: 100vh;
  }
}

.main-commands__title-block, .main-commands__list-wrapper{
  display: flex;
  align-items: center;

  @include at-xs{

  }

  @include at-lg{
    // position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.main-commands__list-wrapper{
  .link-with-icon{
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: 40px;

    @include at-xs{
      left: 32px;
    }

    @include at-sm{
      left: 40px;
    }
  }
}

.main-commands__list{
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 3fr 1fr;
  grid-gap: 40px;
  width: 100%;

  @include at-xs{
    grid-template-columns: 3fr;
    .empty{
      display: none;
    }
  }

  @include at-sm{
    grid-template-columns: 3fr 3fr 3fr;
  }

  @include at-lg{
    .empty{
      display: block;
    }

    grid-template-columns: 2fr 3fr 3fr 3fr 1fr;
  }

  .link-with-arrow{
    z-index: 5;
  }
}

.price-commands__list{
  display: grid;
  grid-template-columns: 2fr 9fr 1fr;
  grid-gap: 40px;
  width: 100%;

  @include at-xs{
    grid-template-columns: 3fr;
    .empty{
      display: none;
    }
  }

  @include at-lg{
    .empty{
      display: block;
    }

    grid-template-columns: 2fr 9fr 1fr;
  }

  .link-with-arrow{
    z-index: 5;
  }
}

.price-commands__list-wrapper{
  display: grid;
  grid-gap: 40px;

  @include at-xs{
    grid-template-columns: 3fr;
  }

  @include at-sm{
    grid-template-columns: 3fr 3fr 3fr;
  }
}

.main-commands__title-block{
  .big-title{
    @include marginBottom32();
  }

  .container{
    @include at-lg{
      position: absolute;
      top: 140px;
    }
  }
}

