@import "../base/mixins";

.form-page__wrapper{
  background: white;
  margin-top: 10%;
  margin-left: calc((100vw - 40px) / 12);
  margin-right: calc((100vw - 40px) / 12);
}

.form-page {
  display: grid;


  @include at-xs {
    grid-template-columns: 1fr 11fr;
  }

  @include at-md {
    grid-template-columns: 1fr 3fr 1fr 5fr;
    grid-gap: 40px;
  }
}

.base-form {
  .MuiFormControlLabel-label {
    @include font16();
    color: #00043A;
  }
}

.base-form__input {
  display: block;
  width: 100%;

  .MuiInput-input {
    display: block;
    width: 100%;
    height: 80px;
    border: 1px solid #979797;
    padding-top: 0;
    padding-bottom: 0;
    @include paddingRight32();
    @include paddingLeft32();
    line-height: 80px;
  }

  .MuiInputBase-input.Mui-disabled {
    opacity: 0.3;
  }


  .MuiFormLabel-root {
    @include font16();
    color: #979797 !important;
    top: 5px;
    left: 32px;
  }

  .MuiFormLabel-root.Mui-disabled {
    opacity: 0.3;
  }

  .Mui-focused {
    z-index: 1;

    input {
      border-color: #00FFFF;
    }
  }

  &.error {
    .MuiInput-input {
      border-color: red;
      z-index: 1;
    }
  }

  .MuiInputBase-root {
    margin-top: -1px;
  }

  .MuiInput-underline:after, .MuiInput-underline:before {
    display: none;
  }
}

.base-form__checkbox {
  @include marginBottom8();
}

.base-form__actions-block {
  @include paddingTop64();
  display: flex;

  @include at-xs {
    flex-direction: column;
  }

  @include at-sm {
    flex-direction: row;
  }
}

.base-form__actions-block-button {
  @include marginRight32();
}

.base-form__actions-description {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.583333px;
  color: #979797;
  margin-right: 5%;

  @include at-xs {
    margin-top: 30px;
  }

  @include at-sm {
    margin-top: 0;
  }
}

.base-form__date-block {
  display: flex;
  align-items: center;
  justify-content: stretch;

  .base-form__select {
    &:nth-child(2n) {
      margin-left: -1px;
    }
  }
}

.base-form__select {
  width: 100%;

  .MuiInputBase-root {
    width: 100%;
  }

  .MuiFormControl-root {
    width: 100%;
  }
}

.base-form__select-option {
  font-family: IBM Plex Mono !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 32px !important;
  color: #00043A !important;

  &:hover {
    background: #F2F2F2 !important;
  }
}

.base-form__select-option.Mui-selected {
  background: #20FFFF !important;
}

.base-form__select-arrow {
  position: absolute;
  top: 24px;
  right: 15px;
}