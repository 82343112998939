@import '../base/mixins';

.advertisers-page {

}

.advertisers-page__prices-wrapper {
  .main-cybersport__row-2 {
    @include marginTop128();
  }
}

.advertisers-page__hat-block {
  @include at-xs {
    padding-top: 206px;
  }

  @include at-md {
    padding-top: 412px;
  }
}

.advertisers-page__title-block {

}

.advertisers-page__top-title {
  display: flex;
  align-items: center;
}

.advertisers-page__top-title-icon {
  @include marginLeft32();
}

.advertisers-page__title-description-block {
  display: grid;
  grid-template-columns: 5fr 1fr 2fr 4fr;
  grid-gap: 40px;
  @include marginTop80();

  @include at-xs {
    grid-template-columns: 1fr;
    .empty {
      display: none;
    }
  }

  @include at-sm {
    grid-template-columns: 5fr 5fr;
  }

  @include at-md {
    grid-template-columns: 5fr 1fr 3fr 2fr;
    .empty {
      display: block;
    }
  }

  @include at-lg {
    grid-template-columns: 5fr 1fr 2fr 4fr;
  }
}

.advertisers-page__title-description {
  font-weight: 200;
  @include font48();
  color: #FFFFFF;
}

.advertisers-page__title-description-small {
  @include font16();
  color: #FFFFFF;
}

.advertisers-page__audience-block {
  //margin-top: 192px;
  position: relative;

  @include marginTop256();
}

.advertisers-page__audience-bg {
  position: absolute;
  top: 0;
  right: 0;
}

.advertisers-page__audience-content {
  position: relative;
}

.advertisers-page__audience-button-block {
  text-align: center;
}

.advertisers-page__audience-button {
  display: inline-block;
  cursor: pointer;
}

.advertisers-page__audience-bottom-block {
  @include marginTop256();
}

.advertisers-page__prices-description {
  @include marginTop128();

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    @include font16();
    color: #00FFFF;
    @include marginBottom24();
  }
}

.advertisers-page__command-block {
  position: relative;
  @include marginTop256();
  border-bottom: 1px solid #00FFFF;
}

.advertisers-page__command-block-wrapper {
  position: relative;
}

.advertisers-page__command-title-block {
  position: absolute;
  top: 0;
  left: 0;
}

.advertisers-page__command-slider {
  .slick-track {
    margin-left: -250px;
  }
}

.advertisers-page__command-slide {
  padding: 0 100px;
}

.advertisers-page__command-slide-wrapper {
  position: relative;
}

.advertisers-page__command-slide-image {
  height: 800px;
}

.advertisers-page__command-slide-text {
  position: absolute;
  bottom: 111px;
  right: 0;
}

.advertisers-page__command-slide-title {
  font-weight: bold;
  @include font48();
  text-align: center;
  color: #00FFFF;
  @include marginBottom48();
}

.advertisers-page__command-slide-description {
  @include font16();
  color: #00FFFF;
  width: 50%;
  float: right;
}

.advertisers-form {
  @include marginTop256();
}

.advertisers-form-wrapper {
  display: grid;

  grid-gap: 40px;
  @include marginTop96();
  @include marginBottom256();

  @include at-xs {
    grid-template-columns: 1fr;

    .empty {
      display: none;
    }
  }

  @include at-md {
    grid-template-columns: 5fr 5fr 2fr;

    .empty {
      display: block;
    }
  }

  .base-form__input .MuiInput-input {
    color: white;
  }
}

.advertisers-form__title {
  @include font48();
  color: #00FFFF;
}

.advertisers-form__content-description {
  @include font32();
  color: #FFFFFF;
  @include marginBottom48();
}

.advertisers-form__contacts {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  @include marginTop80();

  @include at-xs {
    grid-template-columns: 1fr;
  }

  @include at-sm {
    grid-template-columns: 3fr 2fr;
  }
}

.advertisers-form__contacts-column {

}

.advertisers-form__contact {
  font-weight: 200;
  @include font32();
  color: #00FFFF;

  a {
    text-decoration: none !important;
    color: white;
  }
}

.advertisers-form__contact-link {
  display: flex;
  align-items: center;
  text-decoration: none !important;

  &:hover {
    .advertisers-form__contact-link-text {
      border-bottom-color: transparent;
    }
  }
}

.advertisers-form__contact-link-icon {
  @include marginRight8();
}

.advertisers-form__contact-link-text {
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
}

.advertisers-events-slider {

  .slick-current {
    .advertisers-events-slide {
      margin-left: 0;
    }

    .advertisers-events-slide__image {
      padding-left: 196px;
    }

    .advertisers-events-slide__title-block {
      .big-title {
        color: white;
      }
    }
  }
}

.advertisers-events-slide {
  margin-left: -80px;
}

.advertisers-events-slide-wrapper {
  position: relative;
}

.advertisers-events-slide__image {

}

.advertisers-events-slide__title-block {
  position: absolute;
  top: 140px;

  .big-title {
    color: #00FFFF;
  }
}

.advertisers-events-slide__title-block-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.advertisers-sponsors-block {
  @include at-lg {
    padding: 0 196px;
  }

  @include paddingBottom256();
  @include paddingTop256();
}

.advertisers-sponsors-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include at-xs {
    flex-direction: column;
  }

  @include at-lg {
    flex-direction: row;
  }
}

.advertisers-sponsor {
  @include at-xs {
    margin-bottom: 48px;
  }

  @include at-lg {
    margin-bottom: 0;
  }
}