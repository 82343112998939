@import "../base/mixins";

.content-preview {
  position: relative;
  width: 100%;

  @include at-xs{
    @include marginBottom128();
  }

  @include at-md{
    margin-top: 0;
  }

  &:hover {
    @include at-lg{
      .content-preview__icon {
        svg path, svg circle {
          stroke: #00FFFF;
        }
      }

      .content-preview__mask {
        animation: backgroundPosition 3s ease-in-out infinite;
        opacity: 1;

        svg path {
          animation: draw 2s linear forwards;
        }
      }
    }
  }
}

.content-preview__wrapper{
  position: relative;
  z-index: 2;
}

.content-preview__top-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include at-xs{
    flex-direction: column;
    align-items: flex-start;
  }

  @include at-md{
    flex-direction: row;
    align-items: flex-end;
  }
}


.content-preview__icon {
  display: flex;

  @include at-xs{
    margin-bottom: 12px;
  }

  @include at-md{
    margin-bottom: 0;
  }

  svg{
    height: auto;
    @include at-xs{
      width: 32px;
    }

    @include at-md{
      width: auto;
    }
  }

  svg path {
    stroke: #FFF;
    transition: stroke 0.2s linear;
  }
}

.content-preview__link {
  display: flex;
  align-items: center;
  text-decoration: none !important;
  color: white;
}

.content-preview__link-text {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.680556px;
  text-transform: uppercase;
  color: white;
  @include marginRight16();
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.content-preview__link-arrow {

}

.content-preview__description {
  @include marginTop64();
  font-weight: 200;
  @include font32();
  color: #00FFFF;

  span {
    font-weight: bold;
  }
}

.content-preview__mask {
  min-width: 38vw;
  //min-height: 38vw;
  //width: 150%;
  //height: 150%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: 1px solid #00FFFF;
  clip-path: polygon(88.7% 0%, 100% 73%, 94.5% 99.4%, 1% 99.4%, 0% 72%, 3.3% 0%);
  background-image: url(../../images/content_bg.svg);
  background-repeat: no-repeat;
  background-size: 150%;
  overflow: hidden;
  background-position-y: 0%;
  transition: background-position-y 0.4s linear, opacity 0.2s linear;
  opacity: 0;

  svg path {
    transition: all 0.3s linear;
    stroke-dasharray: 3000;
    stroke-dashoffset: 5000;
  }
}

@keyframes backgroundPosition {
  0%, 100% {
    background-position-y: 0%;
  }
  50% {
    background-position-y: 100%;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.content-preview__mask--revert {
  clip-path: polygon(97.5% 0%, 100% 27%, 96% 99.5%, 10% 99.5%, 0% 80%, 18% 0%);
  background-image: url(../../images/content_bg.svg);
  background-size: cover;
}

.content-preview__mask-border {
  width: 100%;
  height: 100%;
}

@keyframes cardAnim {
  0%, 100% {
    d: path("M25.427 1l636.76.64L749 538.744 705.575 728H9.142L1 537.744 25.427 1z")
  }
  50% {
    d: path("M1 1h920")
  }
}

.content-preview__price-block{
  @include marginTop32();
}

.content-preview__price-prefix{
  @include font16();
  text-transform: uppercase;
  color: #FFFFFF;
}

.content-preview__price{
  font-weight: bold;
  @include font32();
  color: #FFFFFF;
}

.content-preview__price-description{
  @include font16();
  text-transform: uppercase;
  color: #FFFFFF;
}