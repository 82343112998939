@import "../base/mixins";

@keyframes rotateCircle {
  to {
    transform: rotate(360deg);
  }
}

.decoration-polygon-circle {
  position: absolute;
  top: -10vh;

  @include at-md{
    right: -70vw;
  }

  @include at-lg{
    right: -45vw;
  }

  svg {
    height: 120vh;
    fill: #000028;

    path {
      cursor: pointer;
    }
  }
}


.decoration-polygon-circle__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;


  .decoration-polygon-circle {
    @include at-xs{
      display: none;
    }

    @include at-sm{
      display: block;
    }

    &.hover{
      svg {
        animation: rotateCircle 5s linear infinite;
        fill: #23003A;
      }
    }
  }

  &.back {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //overflow: hidden;
    width: 20vw;

    .decoration-polygon-circle {
      left: -45vw;
      right: auto;

      @include at-md{
        left: -70vw;
      }

      @include at-lg{
        left: -45vw;
      }

      svg {
        fill: #23003A;
      }

      &.hover{
        svg {
          animation: rotateCircle 5s linear infinite;
          fill: #000028;
        }
      }
    }
  }

  &.back-black{
    .decoration-polygon-circle {
      svg{
        fill: #000028;
      }
    }
  }

  &.black {
    .decoration-polygon-circle {
      svg {
        fill: #000028;
      }

      &.hover{
        svg {
          animation: rotateCircle 5s linear infinite;
          fill: #000028;
        }
      }
    }
  }
}