@import "../base/mixins";

.prices-page {
  @include at-xs {
    padding-top: 64px;
  }

  @include at-md {
    padding-top: 80px;
  }

  @include at-lg {
    @include paddingTop64();
  }

  .main-commands-block {
    height: auto;
    min-height: 0;
    @include marginBottom256();
  }
}

.prices-list-container {
  @include at-xs {
    margin-top: 128px;
  }

  @include at-md {
    margin-top: 0;
  }
}

.prices-list-wrapper {
  display: grid;
  grid-template-columns: 2fr 9fr 1fr;
  @include marginBottom64();
  @include marginTop64();

  @include at-xs {
    grid-template-columns: 1fr;

    .empty {
      display: block;
    }
  }

  @include at-sm {
    grid-gap: 40px;
  }

  @include at-lg {
    grid-template-columns: 2fr 9fr 1fr;

    .empty {
      display: block;
    }
  }
}

.prices-list-block {

}

.prices-page__description-wrapper {
  display: grid;

  grid-gap: 40px;
  @include marginBottom128();

  @include at-xs {
    grid-template-columns: 1fr;
  }

  @include at-md {
    grid-template-columns: 5fr 7fr;
  }
}

.prices-page__description {
  font-weight: 200;
  @include font48();
  color: #FFFFFF;
}

.prices-list__title-block {
  @include marginBottom64();
  text-decoration: none !important;

  @include at-xs {
    display: block;
  }

  @include at-sm {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
}

.prices-list__title-block--single {
  @include marginBottom96();

  &:last-child {
    @include at-xs {
      margin-bottom: 64px;
    }

    @include at-sm {
      margin-bottom: 0;
    }
  }
}

a.prices-list__title-block--single {
  .prices-list__title {
    color: #00FFFF;
    border-bottom: 1px solid rgba(0, 255, 255, 0.3);
  }

  &:hover {
    .prices-list__title {
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
  }
}

.prices-list__title {
  font-weight: bold;
  @include font48();
  text-transform: uppercase;
  color: #FFFFFF;
  display: inline-block;

  @include at-xs {
    margin-bottom: 4px;
  }

  @include at-sm {
    margin-bottom: 0;
  }
}

.prices-list__title-description-block {
  text-decoration: none !important;

  @include at-sm {
    text-align: right;
  }

  &:hover {
    .price-list__bar-link-text {
      border-bottom-color: #00FFFF;
    }
  }
}

.prices-list__title-description {
  font-weight: bold;
  @include font48();
  text-transform: uppercase;
  color: #FFFFFF;

  span {
    @include font18();
    @include paddingLeft32();
  }
}

.prices-list__title-description-small {
  font-weight: bold;
  @include font32();
  text-transform: uppercase;
  color: #00FFFF;

  span {
    @include font18();
    @include paddingLeft32();
  }
}

.price-list {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-gap: 40px;
  //margin-bottom: 124px;
  @include marginBottom128();

  @include at-xs {
    grid-template-columns: 1fr;
  }

  @include at-sm {
    grid-template-columns: 3fr 3fr 3fr;
  }
}


.price-list__bar-link {
  display: flex;
  align-items: center;

  @include at-xs {
    margin-top: 18px;
  }

  @include at-md {
    margin-top: 0;
  }
}

.price-list__bar-link-icon {
  @include marginRight32();
}

.price-list__bar-link-text {
  @include font48();
  text-transform: uppercase;
  color: #00FFFF;
  border-bottom: 1px dashed rgba(0, 255, 255, 0.3);
  font-weight: 700;
  @include paddingBottom8();
}

.prices-page__work {
  .big-title {
    @include marginBottom64();
  }
}