@import '../base/mixins';

.service-preview{
  text-decoration: none !important;
  position: relative;

  .game-preview__text{
    width: 100%;
  }

  &:hover{
    .service-preview__description{
      transform: translateY(10px);
    }
  }
}

.service-preview__description{
  color: #00FFFF;
  @include paddingLeft48();
  @include paddingTop32();
  @include font16();
  transform: translateY(0);
  transition: transform 0.3s linear;
  position: relative;
}