@import '../base/mixins';

.services-list-block{
  @include at-xs{
    margin-top: 100px;
  }

  @include  at-sm{
    margin-top: 0;
  }
}

.services-list-wrapper{
  display: grid;
  @include marginTop80();
  @include paddingTop8();
  @include marginBottom256();

  @include at-xs{
    grid-template-columns: 1fr;
  }

  @include at-md{
    grid-gap: 40px;
    grid-template-columns: 2fr 9fr 1fr;
  }
}

.services-list{
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 90px;

  @include at-xs{
    grid-template-columns: 3fr;
    grid-column-gap: 20px;
    grid-row-gap: 40px;
  }

  @include at-sm{
    grid-template-columns: 3fr 3fr;
    grid-column-gap: 30px;
    grid-row-gap: 60px;
  }

  @include at-md{
    grid-template-columns: 3fr 3fr 3fr;
    grid-column-gap: 40px;
    grid-row-gap: 90px;
  }
}