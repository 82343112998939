@import "../base/mixins";

.event-page {
  .base-form {
    margin-left: -20px;

    .event-page__content-description {
      @include paddingTop128();
    }
  }
}

.event-page__preview-image {
  width: 100%;
  height: auto;
}

.event-page-content {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 4fr 1fr;

  @include at-xs {
    grid-template-columns: 1fr 11fr;
  }

  @include at-md {
    grid-gap: 40px;
    grid-template-columns: 1fr 3fr 1fr 4fr 1fr;
  }
}

.event-page__title-block {

}

.event-page__game {
  @include paddingTop96();
  display: flex;
  align-items: center;
}

.event-page__game-icon {
  @include marginRight8();

  svg {
    path {
      fill: #160F66;
    }
  }
}

.event-page__game-name {
  @include font18();
  text-transform: uppercase;
  color: #160F66;
}

.event-page__title {
  @include font48();
  color: #160F66;
  @include paddingTop128();
  margin: 0;
  font-weight: 400;

  @include at-xs {
    padding-right: 48px;
    padding-bottom: 48px;
  }

  @include at-md {
    padding-right: 0;
    padding-bottom: 0;
  }
}

.event-page__small-title {
  font-weight: 500;
  @include font24();
  text-transform: uppercase;
  color: #160F66;
  margin-top: 0;
  @include paddingTop96();

  @include at-xs {
    padding-right: 48px;
  }

  @include at-md {
    padding-right: 0;
  }
}

.event-page__content-block {
  @include paddingTop96();
  @include paddingBottom128();
  @include marginBottom96();
}

.event-page__content-description-wrapper {

}

.event-page__content-description {
  @include font32();
  color: #00043A;
  @include paddingBottom32();
  @include paddingTop48();

  @include at-xs {
    padding-right: 48px;
  }

  @include at-md {
    padding-right: 0;
  }

  &:first-of-type{
    @include paddingTop128();
  }
}

.event-page__content-description-small {
  p {
    @include font16();
    color: #00043A;
    position: relative;

    &:before {
      content: '/';
      position: absolute;
      top: 0;
      left: -24px;
    }
  }
}

.event-page__content-text {
  @include font16();
  color: #00043A;

  @include at-xs {
    padding-right: 48px;
  }

  @include at-md {
    padding-right: 0;
  }
}

.event-page__button-block {
  @include marginTop96();
}

.event-page__hat-image {
  width: 100%;
  height: auto;
}