@import "../base/mixins";

.main-footer {
  background: black;
  //padding-top: 69px;
  @include paddingBottom96();
  @include paddingTop64();
}

.main-footer__wrapper {
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 2fr 2fr 1fr;
  grid-gap: 40px;

  @include at-xs {
    grid-template-columns: 2fr;
    .empty {
      display: none;
    }
  }

  @include at-sm {
    grid-template-columns: 2fr 2fr;
  }

  @include at-md {
    grid-template-columns: 2fr 2fr 2fr 2fr;
  }

  @include at-lg {
    grid-template-columns: 1fr 4fr 2fr 2fr 2fr 1fr;
    .empty {
      display: block;
    }
  }
}

.maim-footer__column {
  .base-link {
    @include marginBottom24();
    display: inline;
    padding-bottom: 3px;
  }

  .link-with-icon {
    margin-top: -10px;
  }
}

.maim-footer__column-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.maim-footer__links-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;


  .base-link {
    @include marginRight32();
  }
}

.maim-footer__column-contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-footer__copywrite {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.777778px;
  color: #FFFFFF;
  opacity: 0.5;

  @include at-xs{
    margin-top: 20px;
  }

  @include at-md{
    margin-top: 0;
  }
}

.main-footer__make-copywrite {
  display: flex;
  align-items: center;
  text-decoration: none !important;
}

.main-footer__make-copywrite--pc {
  @include at-xs {
    display: none;
  }

  @include at-md {
    display: flex;
  }
}

.main-footer__make-copywrite--mobile {
  margin-top: 20px;
  @include at-xs {
    display: flex;

  }

  @include at-md {
    display: none;
  }
}

.main-footer__make-copywrite-img {
  @include marginRight4();
  transform: translateX(-6px);
}

.main-footer__make-copywrite-text {
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  margin-top: -6px;
  @include paddingBottom4();
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.main-footer__phone {
  @include font16();
  color: #FFFFFF;
  @include marginBottom16();

  a {
    text-decoration: none !important;
    color: white;
  }
}

.main-footer__address {
  @include font16();
  color: #FFFFFF;
}

.maim-footer__social-block {
  display: flex;
  align-items: center;
  @include marginBottom48()
}

.maim-footer__social-link {
  @include marginRight16();
  @include paddingRight16();
}


.footer__links-block {
  display: flex;
  flex-direction: column;
}

.footer__link {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.777778px;
  color: #FFFFFF;
  text-decoration: none !important;
  opacity: 0.5;
  margin-bottom: 8px;

  &:hover {
    color: #00FFFF;
  }
}