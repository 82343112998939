@import "../base/mixins";

.events-page{
  padding-top: 196px;
  position: relative;
  overflow: hidden;
  background: #23003A;
}

.commands-page{
  background: #000028;
}

.events-list-wrapper{
  display: grid;
  grid-gap: 40px;
  padding-bottom: 196px;

  @include at-xs{
    grid-template-columns: 1fr;
  }

  @include at-sm{
    grid-template-columns: 2fr 9fr;
  }

  @include at-lg{
    grid-template-columns: 2fr 9fr 1fr;
  }
}

.events-list{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  z-index: 2;
  position: relative;

  @include at-xs{
    grid-template-columns: 1fr;
  }

  @include at-sm{
    grid-template-columns: 1fr 1fr;
  }

  @include at-lg{
    grid-template-columns: 1fr 1fr 1fr;
  }
}