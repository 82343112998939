@import '../base/mixins';

.base-button-transparent {
  position: relative;
  //width: 118px;
  height: 48px;
  display: inline-block;
  line-height: 46px;
  color: white;
  border: 1px solid white;
  border-left: 0;
  border-right: 0;
  text-decoration: none !important;
  margin-left: 17.5px;
  margin-right: 17px;
  text-align: center;
  text-transform: uppercase;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  outline: none!important;
  padding: 0 10px;
}

.base-button-transparent--decoration-right {
  &:before {
    content: " ";
    position: absolute;
    display: block;
    width: 50%;
    height: 80%;
    top: -1px;
    right: -10px;
    z-index: -1;
    border-right: 1px solid white;
    transform-origin: bottom right;
    transform: skew(15deg, 0deg);
  }

  &:after {
    content: " ";
    position: absolute;
    display: block;
    width: 50%;
    height: 24.5%;
    bottom: -1px;
    right: 0;
    z-index: -1;
    border-right: 1px solid white;
    transform-origin: bottom right;
    transform: skew(-41.4deg, 0deg);
  }
}

.base-button-transparent--decoration-left {


  &:before {
    content: " ";
    position: absolute;
    display: block;
    width: 50%;
    height: 24.5%;
    top: -1px;
    left: -6.5px;
    z-index: -1;
    border-left: 1px solid white;
    transform-origin: bottom right;
    transform: skew(-30deg, 0deg);
  }

  &:after {
    content: " ";
    position: absolute;
    display: block;
    width: 50%;
    height: 80%;
    bottom: -1px;
    left: 0;
    z-index: -1;
    border-left: 1px solid white;
    transform-origin: bottom left;
    transform: skew(10deg, 0deg);
  }
}


.base-button-transparent---blue {
  border: 1px solid #00FFFF;
  border-right: 0;
  border-left: 0;
  color: #00FFFF;
  line-height: 21px;

  .base-button-transparent--decoration-right, .base-button-transparent--decoration-left {
    &:before, &:after {
      border-color: #00FFFF;
    }
  }

  &:hover {
    color: white;
    border-color: white;

    .base-button-transparent--decoration-right, .base-button-transparent--decoration-left {
      &:before, &:after {
        border-color: white;
      }
    }
  }
}


.base-button {
  position: relative;
  //width: 118px;
  height: 80px;
  display: inline-block;
  line-height: 80px;
  text-decoration: none !important;
  text-align: center;
  text-transform: uppercase;
  box-shadow: none;
  cursor: pointer;
  outline: none !important;
  padding: 0 !important;
  background: transparent !important;
  font: inherit;
  border: none !important;
  color: white;

  @include at-xs{
    min-width: 240px;
  }

  @include at-sm{
    min-width: 280px;
  }

  &:hover {
    .base-button__content {
      transform: translate(6px, -6px);
    }

    .base-button__corner-decoration {
      opacity: 1;

      svg {
        stroke: #00FFFF;
      }
    }
  }

  &:active {
    .base-button__content {
      background: #00FFFF;
      transform: translate(0, 0);
    }


    .base-button__corner-decoration {
      opacity: 1;

      svg {
        stroke: white;
      }
    }
  }
}

.base-button__content {
  width: 100%;
  height: 100%;
  clip-path: polygon(calc(100% - 17.52px) 0%, 100% 58.79px, calc(100% - 28.74px) 100%, 8.43px 100%, 0% 23.24px, 16.14px 0%);
  background-color: #1C1982;
  padding: 0 30px;
  transition: all 0.1s linear;
  position: relative;
  @include font24();
  line-height: 80px!important;
  text-transform: uppercase;
}

.base-button__shadow-block {
  position: absolute;
  top: 29.05%;
  bottom: 0;
  left: 0;
  right: 10.3%;
  background: rgba(0, 0, 40, 0.2);
  clip-path: polygon(94.4% 0%, 100% 100%, 3.2% 100%, 0% 0%);
}

.base-button__corner-decoration {
  position: absolute;
  left: 16px;
  bottom: 6px;
  width: 42px;
  height: 42px;
  opacity: 0;
  transition: all 0.1s linear;
  display: flex;
  align-items: center;
}



.base-button__small-blue {
  position: relative;
  //width: 118px;
  height: 48px;
  display: flex;
  line-height: 48px;
  text-decoration: none !important;
  text-align: center;
  text-transform: uppercase;
  box-shadow: none;
  cursor: pointer;
  outline: none !important;
  padding: 0 10px;
  min-width: 190px;
  font: inherit;
  border: none !important;
  color: #000028;
  @include font16();
  background: transparent;
  transition: all 0.1s linear;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: translate(6px, -6px);
    color: #2151A4;

    .base-button__small-blue-decoration{
      background: white;
    }

    .base-button__small-blue-decoration-wrapper{
      filter: drop-shadow(0px 0px 20px rgba(0, 255, 255, 0.302475));
    }
  }

  &:active {
    color: #FFFFFF;
    transform: translate(0, 0);

    .base-button__small-blue-decoration{
      background: #00FFFF;
    }

    .base-button__small-blue-decoration-wrapper{
      filter: drop-shadow(0px 0px 20px #00FFFF);
    }
  }
}

.base-button__small-blue-decoration-wrapper{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.base-button__small-blue-decoration{
  width: 100%;
  height: 100%;
  clip-path: polygon(calc(100% - 10.74px) 0%, 100% 38.46px, calc(100% - 10.74px) 100%, 6.91px 100%, 0% 8.47px, 6.961px 0%);
  padding: 0 10px;
  transition: all 0.1s linear;
  position: relative;
  background: #00FFFF;
}

.base-button__small-blue-content{
  @include font16();
  line-height: 48px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.base-button-with-arrow__text-wrapper{
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

.base-button-with-arrow__text{
  margin-right: 18px;
  border-bottom: 1px dashed rgba(28,25,130, 0.3);
  line-height: 32px;
}

.base-button-with-arrow__arrow{
  display: flex;
  align-items: center;
}


.base-button-with-arrow__border-decoration-wrapper{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.base-button-with-arrow__border-decoration{
  width: 100%;
  height: 100%;
  clip-path: polygon(calc(100% - 17.51px) 0%, 100% 58.79px, calc(100% - 28.74px) 100%, 8.43px 100%, 0% 23.24px, 16.24px 0%);
  padding: 0 10px;
  transition: all 0.1s linear;
  position: relative;
  background: #1C1982;
}

.base-button-with-arrow__bg-decoration-wrapper{
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: 8px;
  left: 8px;
}

.base-button-with-arrow__bg-decoration{
  width: 100%;
  height: 100%;
  clip-path: polygon(calc(100% - 17.51px) 0%, calc(100% - 3px) 47.79px, calc(100% - 24.74px) 100%, 8.43px 100%, 1px 17.24px, 13.24px 0%);
  padding: 0 10px;
  transition: all 0.1s linear;
  position: relative;
  background: white;
}

.base-button__with-arrow {
  position: relative;
  //width: 118px;
  height: 80px;
  display: inline-block;
  line-height: 80px;
  text-decoration: none !important;
  text-align: center;
  text-transform: uppercase;
  box-shadow: none;
  cursor: pointer;
  outline: none !important;
  background: transparent !important;
  min-width: 280px;
  font: inherit;
  border-right: 0;
  border-left: 0;
  color: #1C1982;
  line-height: 64px;
  padding: 0 60px;
  transition: all 0.1s linear;

  @include at-xs{
    min-width: 230px;
    padding: 0 30px;
  }

  @include at-sm{
    min-width: 280px;
    padding: 0 60px;
  }

  .base-button-with-arrow__text{
    @include font24();
    font-weight: bold;
    text-transform: uppercase;
  }

  .base-button-with-arrow__arrow{
    svg{
      fill: #1C1982;
    }
  }


  &:hover {
    color: #2151A4;
    border-color: #2151A4;
    transform: translate(6px, -6px);

    .base-button-with-arrow__text{
      border-bottom: 1px dashed rgba(33, 81, 164, 0.3);
    }

    .base-button-with-arrow__arrow{
      svg{
        fill: #2151A4;
      }
    }

    .base-button-with-arrow__border-decoration{
      background: #2151A4;
    }
  }

  &:active {
    color: #00FFFF;
    border-color: #00FFFF;

    .base-button-with-arrow__text{
      border-bottom: 1px dashed rgba(0, 255, 255, 0.3);
    }

    .base-button-with-arrow__arrow{
      svg{
        fill: #00FFFF;
      }
    }

    .base-button-with-arrow__border-decoration{
      background: #00FFFF;
    }
  }

}


