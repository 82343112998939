@mixin at-xs {
  @media (min-width: 320px) {
    @content;
  }
}

@mixin at-sm {
  @media (min-width: 750px) {
    @content;
  }
}

@mixin at-md {
  @media (min-width: 1050px) {
    @content;
  }
}

@mixin at-lg {
  @media (min-width: 1300px) {
    @content;
  }
}

@mixin at-fh {
  @media (min-width: 1600px) {
    @content;
  }
}




@mixin font112 {
  @media (min-width: 320px) {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 1.36111px;
  }

  @media (min-width: 750px) {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 1.36111px;
  }

  @media (min-width: 1050px) {
    font-weight: bold;
    font-size: 64px;
    line-height: 72px;
    letter-spacing: 1.36111px;
  }

  @media (min-width: 1300px) {
    font-size: 96px;
    line-height: 104px;
    letter-spacing: 1.36111px;
  }

  @media (min-width: 1600px) {
    font-weight: bold;
    font-size: 112px;
    line-height: 120px;
    letter-spacing: 1.36111px;
  }
}

@mixin font96 {
  @media (min-width: 320px) {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 4.66667px;
  }

  @media (min-width: 750px) {
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 4.66667px;
  }

  @media (min-width: 1050px) {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 4.66667px;
  }

  @media (min-width: 1300px) {
    font-weight: bold;
    font-size: 64px;
    line-height: 72px;
    letter-spacing: 4.66667px;
  }

  @media (min-width: 1600px) {
    font-weight: bold;
    font-size: 96px;
    line-height: 104px;
    letter-spacing: 4.66667px;
  }
}

@mixin font48 {
  @media (min-width: 320px) {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 2.33333px;
  }

  @media (min-width: 750px) {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 2.33333px;
  }

  @media (min-width: 1050px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 2.33333px;
  }

  @media (min-width: 1300px) {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 2.33333px;
  }

  @media (min-width: 1600px) {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 2.33333px;
  }
}


@mixin font32 {
  @media (min-width: 320px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.55556px;
  }

  @media (min-width: 750px) {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 1.55556px;
  }

  @media (min-width: 1050px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1.55556px;
  }

  @media (min-width: 1300px) {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 1.55556px;
  }

  @media (min-width: 1600px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 1.55556px;
  }
}

@mixin font24 {
  @media (min-width: 320px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 750px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (min-width: 1050px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (min-width: 1300px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (min-width: 1600px) {
    font-size: 24px;
    line-height: 32px;
  }
}


@mixin font18 {
  @media (min-width: 320px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media (min-width: 750px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 1050px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 1300px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 1600px) {
    font-size: 18px;
    line-height: 26px;
  }
}


@mixin font16 {
  @media (min-width: 320px) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.777778px;
  }

  @media (min-width: 750px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media (min-width: 1050px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.777778px;
  }

  @media (min-width: 1300px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.777778px;

  }

  @media (min-width: 1600px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.777778px;
  }
}





@mixin marginTop512 {
  @media (min-width: 320px) {
    margin-top: 128px;
  }

  @media (min-width: 960px) {
    margin-top: 192px;
  }

  @media (min-width: 1280px) {
    margin-top: 192px;
  }

  @media (min-width: 1440px) {
    margin-top: 256px;
  }

  @media (min-width: 1920px) {
    margin-top: 512px;
  }
}


@mixin marginTop256 {
  @media (min-width: 320px) {
    margin-top: 96px;
  }

  @media (min-width: 960px) {
    margin-top: 128px;
  }

  @media (min-width: 1280px) {
    margin-top: 128px;
  }

  @media (min-width: 1440px) {
    margin-top: 192px;
  }

  @media (min-width: 1920px) {
    margin-top: 256px;
  }
}


@mixin marginTop128 {
  @media (min-width: 320px) {
    margin-top: 64px;
  }

  @media (min-width: 960px) {
    margin-top: 80px;
  }

  @media (min-width: 1280px) {
    margin-top: 80px;
  }

  @media (min-width: 1440px) {
    margin-top: 96px;
  }

  @media (min-width: 1920px) {
    margin-top: 128px;
  }
}


@mixin marginTop96 {
  @media (min-width: 320px) {
    margin-top: 32px;
  }

  @media (min-width: 960px) {
    margin-top: 64px;
  }

  @media (min-width: 1280px) {
    margin-top: 64px;
  }

  @media (min-width: 1440px) {
    margin-top: 80px;
  }

  @media (min-width: 1920px) {
    margin-top: 96px;
  }
}


@mixin marginTop80 {
  @media (min-width: 320px) {
    margin-top: 32px;
  }

  @media (min-width: 960px) {
    margin-top: 48px;
  }

  @media (min-width: 1280px) {
    margin-top: 48px;
  }

  @media (min-width: 1440px) {
    margin-top: 64px;
  }

  @media (min-width: 1920px) {
    margin-top: 80px;
  }
}


@mixin marginTop64 {
  @media (min-width: 320px) {
    margin-top: 32px;
  }

  @media (min-width: 960px) {
    margin-top: 36px;
  }

  @media (min-width: 1280px) {
    margin-top: 36px;
  }

  @media (min-width: 1440px) {
    margin-top: 48px;
  }

  @media (min-width: 1920px) {
    margin-top: 64px;
  }
}


@mixin marginTop48 {
  @media (min-width: 320px) {
    margin-top: 24px;
  }

  @media (min-width: 960px) {
    margin-top: 32px;
  }

  @media (min-width: 1280px) {
    margin-top: 32px;
  }

  @media (min-width: 1440px) {
    margin-top: 36px;
  }

  @media (min-width: 1920px) {
    margin-top: 48px;
  }
}


@mixin marginTop32 {
  @media (min-width: 320px) {
    margin-top: 24px;
  }

  @media (min-width: 960px) {
    margin-top: 24px;
  }

  @media (min-width: 1280px) {
    margin-top: 24px;
  }

  @media (min-width: 1440px) {
    margin-top: 32px;
  }

  @media (min-width: 1920px) {
    margin-top: 32px;
  }
}


@mixin marginTop24 {
  @media (min-width: 320px) {
    margin-top: 16px;
  }

  @media (min-width: 960px) {
    margin-top: 18px;
  }

  @media (min-width: 1280px) {
    margin-top: 18px;
  }

  @media (min-width: 1440px) {
    margin-top: 24px;
  }

  @media (min-width: 1920px) {
    margin-top: 24px;
  }
}


@mixin marginTop16 {
  @media (min-width: 320px) {
    margin-top: 16px;
  }

  @media (min-width: 960px) {
    margin-top: 16px;
  }

  @media (min-width: 1280px) {
    margin-top: 16px;
  }

  @media (min-width: 1440px) {
    margin-top: 16px;
  }

  @media (min-width: 1920px) {
    margin-top: 16px;
  }
}


@mixin marginTop8 {
  @media (min-width: 320px) {
    margin-top: 8px;
  }

  @media (min-width: 960px) {
    margin-top: 8px;
  }

  @media (min-width: 1280px) {
    margin-top: 8px;
  }

  @media (min-width: 1440px) {
    margin-top: 8px;
  }

  @media (min-width: 1920px) {
    margin-top: 8px;
  }
}


@mixin marginTop4 {
  @media (min-width: 320px) {
    margin-top: 4px;
  }

  @media (min-width: 960px) {
    margin-top: 4px;
  }

  @media (min-width: 1280px) {
    margin-top: 4px;
  }

  @media (min-width: 1440px) {
    margin-top: 4px;
  }

  @media (min-width: 1920px) {
    margin-top: 4px;
  }
}









@mixin marginBottom512 {
  @media (min-width: 320px) {
    margin-bottom: 128px;
  }

  @media (min-width: 960px) {
    margin-bottom: 192px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 192px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 256px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 512px;
  }
}


@mixin marginBottom256 {
  @media (min-width: 320px) {
    margin-bottom: 96px;
  }

  @media (min-width: 960px) {
    margin-bottom: 128px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 128px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 192px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 256px;
  }
}


@mixin marginBottom128 {
  @media (min-width: 320px) {
    margin-bottom: 64px;
  }

  @media (min-width: 960px) {
    margin-bottom: 80px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 80px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 96px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 128px;
  }
}


@mixin marginBottom96 {
  @media (min-width: 320px) {
    margin-bottom: 32px;
  }

  @media (min-width: 960px) {
    margin-bottom: 64px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 64px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 80px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 96px;
  }
}


@mixin marginBottom80 {
  @media (min-width: 320px) {
    margin-bottom: 32px;
  }

  @media (min-width: 960px) {
    margin-bottom: 48px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 48px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 64px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 80px;
  }
}


@mixin marginBottom64 {
  @media (min-width: 320px) {
    margin-bottom: 32px;
  }

  @media (min-width: 960px) {
    margin-bottom: 36px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 36px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 48px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 64px;
  }
}


@mixin marginBottom48 {
  @media (min-width: 320px) {
    margin-bottom: 24px;
  }

  @media (min-width: 960px) {
    margin-bottom: 32px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 32px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 36px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 48px;
  }
}


@mixin marginBottom32 {
  @media (min-width: 320px) {
    margin-bottom: 24px;
  }

  @media (min-width: 960px) {
    margin-bottom: 24px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 24px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 32px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 32px;
  }
}


@mixin marginBottom24 {
  @media (min-width: 320px) {
    margin-bottom: 16px;
  }

  @media (min-width: 960px) {
    margin-bottom: 18px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 18px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 24px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 24px;
  }
}


@mixin marginBottom16 {
  @media (min-width: 320px) {
    margin-bottom: 16px;
  }

  @media (min-width: 960px) {
    margin-bottom: 16px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 16px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 16px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 16px;
  }
}


@mixin marginBottom8 {
  @media (min-width: 320px) {
    margin-bottom: 8px;
  }

  @media (min-width: 960px) {
    margin-bottom: 8px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 8px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 8px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 8px;
  }
}


@mixin marginBottom4 {
  @media (min-width: 320px) {
    margin-bottom: 4px;
  }

  @media (min-width: 960px) {
    margin-bottom: 4px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 4px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 4px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 4px;
  }
}






@mixin marginRight512 {
  @media (min-width: 320px) {
    margin-right: 128px;
  }

  @media (min-width: 960px) {
    margin-right: 192px;
  }

  @media (min-width: 1280px) {
    margin-right: 192px;
  }

  @media (min-width: 1440px) {
    margin-right: 256px;
  }

  @media (min-width: 1920px) {
    margin-right: 512px;
  }
}


@mixin marginRight256 {
  @media (min-width: 320px) {
    margin-right: 96px;
  }

  @media (min-width: 960px) {
    margin-right: 128px;
  }

  @media (min-width: 1280px) {
    margin-right: 128px;
  }

  @media (min-width: 1440px) {
    margin-right: 192px;
  }

  @media (min-width: 1920px) {
    margin-right: 256px;
  }
}


@mixin marginRight128 {
  @media (min-width: 320px) {
    margin-right: 64px;
  }

  @media (min-width: 960px) {
    margin-right: 80px;
  }

  @media (min-width: 1280px) {
    margin-right: 80px;
  }

  @media (min-width: 1440px) {
    margin-right: 96px;
  }

  @media (min-width: 1920px) {
    margin-right: 128px;
  }
}


@mixin marginRight96 {
  @media (min-width: 320px) {
    margin-right: 32px;
  }

  @media (min-width: 960px) {
    margin-right: 64px;
  }

  @media (min-width: 1280px) {
    margin-right: 64px;
  }

  @media (min-width: 1440px) {
    margin-right: 80px;
  }

  @media (min-width: 1920px) {
    margin-right: 96px;
  }
}


@mixin marginRight80 {
  @media (min-width: 320px) {
    margin-right: 32px;
  }

  @media (min-width: 960px) {
    margin-right: 48px;
  }

  @media (min-width: 1280px) {
    margin-right: 48px;
  }

  @media (min-width: 1440px) {
    margin-right: 64px;
  }

  @media (min-width: 1920px) {
    margin-right: 80px;
  }
}


@mixin marginRight64 {
  @media (min-width: 320px) {
    margin-right: 32px;
  }

  @media (min-width: 960px) {
    margin-right: 36px;
  }

  @media (min-width: 1280px) {
    margin-right: 36px;
  }

  @media (min-width: 1440px) {
    margin-right: 48px;
  }

  @media (min-width: 1920px) {
    margin-right: 64px;
  }
}


@mixin marginRight48 {
  @media (min-width: 320px) {
    margin-right: 24px;
  }

  @media (min-width: 960px) {
    margin-right: 32px;
  }

  @media (min-width: 1280px) {
    margin-right: 32px;
  }

  @media (min-width: 1440px) {
    margin-right: 36px;
  }

  @media (min-width: 1920px) {
    margin-right: 48px;
  }
}


@mixin marginRight32 {
  @media (min-width: 320px) {
    margin-right: 24px;
  }

  @media (min-width: 960px) {
    margin-right: 24px;
  }

  @media (min-width: 1280px) {
    margin-right: 24px;
  }

  @media (min-width: 1440px) {
    margin-right: 32px;
  }

  @media (min-width: 1920px) {
    margin-right: 32px;
  }
}


@mixin marginRight24 {
  @media (min-width: 320px) {
    margin-right: 16px;
  }

  @media (min-width: 960px) {
    margin-right: 18px;
  }

  @media (min-width: 1280px) {
    margin-right: 18px;
  }

  @media (min-width: 1440px) {
    margin-right: 24px;
  }

  @media (min-width: 1920px) {
    margin-right: 54px;
  }
}


@mixin marginRight16 {
  @media (min-width: 320px) {
    margin-right: 16px;
  }

  @media (min-width: 960px) {
    margin-right: 16px;
  }

  @media (min-width: 1280px) {
    margin-right: 16px;
  }

  @media (min-width: 1440px) {
    margin-right: 16px;
  }

  @media (min-width: 1920px) {
    margin-right: 16px;
  }
}


@mixin marginRight8 {
  @media (min-width: 320px) {
    margin-right: 8px;
  }

  @media (min-width: 960px) {
    margin-right: 8px;
  }

  @media (min-width: 1280px) {
    margin-right: 8px;
  }

  @media (min-width: 1440px) {
    margin-right: 8px;
  }

  @media (min-width: 1920px) {
    margin-right: 8px;
  }
}


@mixin marginRight4 {
  @media (min-width: 320px) {
    margin-right: 4px;
  }

  @media (min-width: 960px) {
    margin-right: 4px;
  }

  @media (min-width: 1280px) {
    margin-right: 4px;
  }

  @media (min-width: 1440px) {
    margin-right: 4px;
  }

  @media (min-width: 1920px) {
    margin-right: 4px;
  }
}









@mixin marginLeft512 {
  @media (min-width: 320px) {
    margin-left: 128px;
  }

  @media (min-width: 960px) {
    margin-left: 192px;
  }

  @media (min-width: 1280px) {
    margin-left: 192px;
  }

  @media (min-width: 1440px) {
    margin-left: 256px;
  }

  @media (min-width: 1920px) {
    margin-left: 512px;
  }
}


@mixin marginLeft256 {
  @media (min-width: 320px) {
    margin-left: 96px;
  }

  @media (min-width: 960px) {
    margin-left: 128px;
  }

  @media (min-width: 1280px) {
    margin-left: 128px;
  }

  @media (min-width: 1440px) {
    margin-left: 192px;
  }

  @media (min-width: 1920px) {
    margin-left: 256px;
  }
}


@mixin marginLeft128 {
  @media (min-width: 320px) {
    margin-left: 64px;
  }

  @media (min-width: 960px) {
    margin-left: 80px;
  }

  @media (min-width: 1280px) {
    margin-left: 80px;
  }

  @media (min-width: 1440px) {
    margin-left: 96px;
  }

  @media (min-width: 1920px) {
    margin-left: 128px;
  }
}


@mixin marginLeft96 {
  @media (min-width: 320px) {
    margin-left: 32px;
  }

  @media (min-width: 960px) {
    margin-left: 64px;
  }

  @media (min-width: 1280px) {
    margin-left: 64px;
  }

  @media (min-width: 1440px) {
    margin-left: 80px;
  }

  @media (min-width: 1920px) {
    margin-left: 96px;
  }
}


@mixin marginLeft80 {
  @media (min-width: 320px) {
    margin-left: 32px;
  }

  @media (min-width: 960px) {
    margin-left: 48px;
  }

  @media (min-width: 1280px) {
    margin-left: 48px;
  }

  @media (min-width: 1440px) {
    margin-left: 64px;
  }

  @media (min-width: 1920px) {
    margin-left: 80px;
  }
}


@mixin marginLeft64 {
  @media (min-width: 320px) {
    margin-left: 32px;
  }

  @media (min-width: 960px) {
    margin-left: 36px;
  }

  @media (min-width: 1280px) {
    margin-left: 36px;
  }

  @media (min-width: 1440px) {
    margin-left: 48px;
  }

  @media (min-width: 1920px) {
    margin-left: 64px;
  }
}


@mixin marginLeft48 {
  @media (min-width: 320px) {
    margin-left: 24px;
  }

  @media (min-width: 960px) {
    margin-left: 32px;
  }

  @media (min-width: 1280px) {
    margin-left: 32px;
  }

  @media (min-width: 1440px) {
    margin-left: 36px;
  }

  @media (min-width: 1920px) {
    margin-left: 48px;
  }
}


@mixin marginLeft32 {
  @media (min-width: 320px) {
    margin-left: 24px;
  }

  @media (min-width: 960px) {
    margin-left: 24px;
  }

  @media (min-width: 1280px) {
    margin-left: 24px;
  }

  @media (min-width: 1440px) {
    margin-left: 32px;
  }

  @media (min-width: 1920px) {
    margin-left: 32px;
  }
}


@mixin marginLeft24 {
  @media (min-width: 320px) {
    margin-left: 16px;
  }

  @media (min-width: 960px) {
    margin-left: 18px;
  }

  @media (min-width: 1280px) {
    margin-left: 18px;
  }

  @media (min-width: 1440px) {
    margin-left: 24px;
  }

  @media (min-width: 1920px) {
    margin-left: 54px;
  }
}


@mixin marginLeft16 {
  @media (min-width: 320px) {
    margin-left: 16px;
  }

  @media (min-width: 960px) {
    margin-left: 16px;
  }

  @media (min-width: 1280px) {
    margin-left: 16px;
  }

  @media (min-width: 1440px) {
    margin-left: 16px;
  }

  @media (min-width: 1920px) {
    margin-left: 16px;
  }
}


@mixin marginLeft8 {
  @media (min-width: 320px) {
    margin-left: 8px;
  }

  @media (min-width: 960px) {
    margin-left: 8px;
  }

  @media (min-width: 1280px) {
    margin-left: 8px;
  }

  @media (min-width: 1440px) {
    margin-left: 8px;
  }

  @media (min-width: 1920px) {
    margin-left: 8px;
  }
}


@mixin marginLeft4 {
  @media (min-width: 320px) {
    margin-left: 4px;
  }

  @media (min-width: 960px) {
    margin-left: 4px;
  }

  @media (min-width: 1280px) {
    margin-left: 4px;
  }

  @media (min-width: 1440px) {
    margin-left: 4px;
  }

  @media (min-width: 1920px) {
    margin-left: 4px;
  }
}






@mixin paddingTop512 {
  @media (min-width: 320px) {
    padding-top: 128px;
  }

  @media (min-width: 960px) {
    padding-top: 192px;
  }

  @media (min-width: 1280px) {
    padding-top: 192px;
  }

  @media (min-width: 1440px) {
    padding-top: 256px;
  }

  @media (min-width: 1920px) {
    padding-top: 512px;
  }
}


@mixin paddingTop256 {
  @media (min-width: 320px) {
    padding-top: 96px;
  }

  @media (min-width: 960px) {
    padding-top: 128px;
  }

  @media (min-width: 1280px) {
    padding-top: 128px;
  }

  @media (min-width: 1440px) {
    padding-top: 192px;
  }

  @media (min-width: 1920px) {
    padding-top: 256px;
  }
}


@mixin paddingTop128 {
  @media (min-width: 320px) {
    padding-top: 64px;
  }

  @media (min-width: 960px) {
    padding-top: 80px;
  }

  @media (min-width: 1280px) {
    padding-top: 80px;
  }

  @media (min-width: 1440px) {
    padding-top: 96px;
  }

  @media (min-width: 1920px) {
    padding-top: 128px;
  }
}


@mixin paddingTop96 {
  @media (min-width: 320px) {
    padding-top: 32px;
  }

  @media (min-width: 960px) {
    padding-top: 64px;
  }

  @media (min-width: 1280px) {
    padding-top: 64px;
  }

  @media (min-width: 1440px) {
    padding-top: 80px;
  }

  @media (min-width: 1920px) {
    padding-top: 96px;
  }
}


@mixin paddingTop80 {
  @media (min-width: 320px) {
    padding-top: 32px;
  }

  @media (min-width: 960px) {
    padding-top: 48px;
  }

  @media (min-width: 1280px) {
    padding-top: 48px;
  }

  @media (min-width: 1440px) {
    padding-top: 64px;
  }

  @media (min-width: 1920px) {
    padding-top: 80px;
  }
}


@mixin paddingTop64 {
  @media (min-width: 320px) {
    padding-top: 32px;
  }

  @media (min-width: 960px) {
    padding-top: 36px;
  }

  @media (min-width: 1280px) {
    padding-top: 36px;
  }

  @media (min-width: 1440px) {
    padding-top: 48px;
  }

  @media (min-width: 1920px) {
    padding-top: 64px;
  }
}


@mixin paddingTop48 {
  @media (min-width: 320px) {
    padding-top: 24px;
  }

  @media (min-width: 960px) {
    padding-top: 32px;
  }

  @media (min-width: 1280px) {
    padding-top: 32px;
  }

  @media (min-width: 1440px) {
    padding-top: 36px;
  }

  @media (min-width: 1920px) {
    padding-top: 48px;
  }
}


@mixin paddingTop32 {
  @media (min-width: 320px) {
    padding-top: 24px;
  }

  @media (min-width: 960px) {
    padding-top: 24px;
  }

  @media (min-width: 1280px) {
    padding-top: 24px;
  }

  @media (min-width: 1440px) {
    padding-top: 32px;
  }

  @media (min-width: 1920px) {
    padding-top: 32px;
  }
}


@mixin paddingTop24 {
  @media (min-width: 320px) {
    padding-top: 16px;
  }

  @media (min-width: 960px) {
    padding-top: 18px;
  }

  @media (min-width: 1280px) {
    padding-top: 18px;
  }

  @media (min-width: 1440px) {
    padding-top: 24px;
  }

  @media (min-width: 1920px) {
    padding-top: 54px;
  }
}


@mixin paddingTop16 {
  @media (min-width: 320px) {
    padding-top: 16px;
  }

  @media (min-width: 960px) {
    padding-top: 16px;
  }

  @media (min-width: 1280px) {
    padding-top: 16px;
  }

  @media (min-width: 1440px) {
    padding-top: 16px;
  }

  @media (min-width: 1920px) {
    padding-top: 16px;
  }
}


@mixin paddingTop8 {
  @media (min-width: 320px) {
    padding-top: 8px;
  }

  @media (min-width: 960px) {
    padding-top: 8px;
  }

  @media (min-width: 1280px) {
    padding-top: 8px;
  }

  @media (min-width: 1440px) {
    padding-top: 8px;
  }

  @media (min-width: 1920px) {
    padding-top: 8px;
  }
}


@mixin paddingTop4 {
  @media (min-width: 320px) {
    padding-top: 4px;
  }

  @media (min-width: 960px) {
    padding-top: 4px;
  }

  @media (min-width: 1280px) {
    padding-top: 4px;
  }

  @media (min-width: 1440px) {
    padding-top: 4px;
  }

  @media (min-width: 1920px) {
    padding-top: 4px;
  }
}









@mixin paddingBottom512 {
  @media (min-width: 320px) {
    padding-bottom: 128px;
  }

  @media (min-width: 960px) {
    padding-bottom: 192px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 192px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 256px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 512px;
  }
}


@mixin paddingBottom256 {
  @media (min-width: 320px) {
    padding-bottom: 96px;
  }

  @media (min-width: 960px) {
    padding-bottom: 128px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 128px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 192px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 256px;
  }
}


@mixin paddingBottom128 {
  @media (min-width: 320px) {
    padding-bottom: 64px;
  }

  @media (min-width: 960px) {
    padding-bottom: 80px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 80px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 96px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 128px;
  }
}


@mixin paddingBottom96 {
  @media (min-width: 320px) {
    padding-bottom: 32px;
  }

  @media (min-width: 960px) {
    padding-bottom: 64px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 64px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 80px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 96px;
  }
}


@mixin paddingBottom80 {
  @media (min-width: 320px) {
    padding-bottom: 32px;
  }

  @media (min-width: 960px) {
    padding-bottom: 48px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 48px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 64px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 80px;
  }
}


@mixin paddingBottom64 {
  @media (min-width: 320px) {
    padding-bottom: 32px;
  }

  @media (min-width: 960px) {
    padding-bottom: 36px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 36px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 48px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 64px;
  }
}


@mixin paddingBottom48 {
  @media (min-width: 320px) {
    padding-bottom: 24px;
  }

  @media (min-width: 960px) {
    padding-bottom: 32px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 32px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 36px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 48px;
  }
}


@mixin paddingBottom32 {
  @media (min-width: 320px) {
    padding-bottom: 24px;
  }

  @media (min-width: 960px) {
    padding-bottom: 24px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 24px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 32px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 32px;
  }
}


@mixin paddingBottom24 {
  @media (min-width: 320px) {
    padding-bottom: 16px;
  }

  @media (min-width: 960px) {
    padding-bottom: 18px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 18px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 24px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 54px;
  }
}


@mixin paddingBottom16 {
  @media (min-width: 320px) {
    padding-bottom: 16px;
  }

  @media (min-width: 960px) {
    padding-bottom: 16px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 16px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 16px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 16px;
  }
}


@mixin paddingBottom8 {
  @media (min-width: 320px) {
    padding-bottom: 8px;
  }

  @media (min-width: 960px) {
    padding-bottom: 8px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 8px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 8px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 8px;
  }
}


@mixin paddingBottom4 {
  @media (min-width: 320px) {
    padding-bottom: 4px;
  }

  @media (min-width: 960px) {
    padding-bottom: 4px;
  }

  @media (min-width: 1280px) {
    padding-bottom: 4px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 4px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 4px;
  }
}






@mixin paddingRight512 {
  @media (min-width: 320px) {
    padding-right: 128px;
  }

  @media (min-width: 960px) {
    padding-right: 192px;
  }

  @media (min-width: 1280px) {
    padding-right: 192px;
  }

  @media (min-width: 1440px) {
    padding-right: 256px;
  }

  @media (min-width: 1920px) {
    padding-right: 512px;
  }
}


@mixin paddingRight256 {
  @media (min-width: 320px) {
    padding-right: 96px;
  }

  @media (min-width: 960px) {
    padding-right: 128px;
  }

  @media (min-width: 1280px) {
    padding-right: 128px;
  }

  @media (min-width: 1440px) {
    padding-right: 192px;
  }

  @media (min-width: 1920px) {
    padding-right: 256px;
  }
}


@mixin paddingRight128 {
  @media (min-width: 320px) {
    padding-right: 64px;
  }

  @media (min-width: 960px) {
    padding-right: 80px;
  }

  @media (min-width: 1280px) {
    padding-right: 80px;
  }

  @media (min-width: 1440px) {
    padding-right: 96px;
  }

  @media (min-width: 1920px) {
    padding-right: 128px;
  }
}


@mixin paddingRight96 {
  @media (min-width: 320px) {
    padding-right: 32px;
  }

  @media (min-width: 960px) {
    padding-right: 64px;
  }

  @media (min-width: 1280px) {
    padding-right: 64px;
  }

  @media (min-width: 1440px) {
    padding-right: 80px;
  }

  @media (min-width: 1920px) {
    padding-right: 96px;
  }
}


@mixin paddingRight80 {
  @media (min-width: 320px) {
    padding-right: 32px;
  }

  @media (min-width: 960px) {
    padding-right: 48px;
  }

  @media (min-width: 1280px) {
    padding-right: 48px;
  }

  @media (min-width: 1440px) {
    padding-right: 64px;
  }

  @media (min-width: 1920px) {
    padding-right: 80px;
  }
}


@mixin paddingRight64 {
  @media (min-width: 320px) {
    padding-right: 32px;
  }

  @media (min-width: 960px) {
    padding-right: 36px;
  }

  @media (min-width: 1280px) {
    padding-right: 36px;
  }

  @media (min-width: 1440px) {
    padding-right: 48px;
  }

  @media (min-width: 1920px) {
    padding-right: 64px;
  }
}


@mixin paddingRight48 {
  @media (min-width: 320px) {
    padding-right: 24px;
  }

  @media (min-width: 960px) {
    padding-right: 32px;
  }

  @media (min-width: 1280px) {
    padding-right: 32px;
  }

  @media (min-width: 1440px) {
    padding-right: 36px;
  }

  @media (min-width: 1920px) {
    padding-right: 48px;
  }
}


@mixin paddingRight32 {
  @media (min-width: 320px) {
    padding-right: 24px;
  }

  @media (min-width: 960px) {
    padding-right: 24px;
  }

  @media (min-width: 1280px) {
    padding-right: 24px;
  }

  @media (min-width: 1440px) {
    padding-right: 32px;
  }

  @media (min-width: 1920px) {
    padding-right: 32px;
  }
}


@mixin paddingRight24 {
  @media (min-width: 320px) {
    padding-right: 16px;
  }

  @media (min-width: 960px) {
    padding-right: 18px;
  }

  @media (min-width: 1280px) {
    padding-right: 18px;
  }

  @media (min-width: 1440px) {
    padding-right: 24px;
  }

  @media (min-width: 1920px) {
    padding-right: 54px;
  }
}


@mixin paddingRight16 {
  @media (min-width: 320px) {
    padding-right: 16px;
  }

  @media (min-width: 960px) {
    padding-right: 16px;
  }

  @media (min-width: 1280px) {
    padding-right: 16px;
  }

  @media (min-width: 1440px) {
    padding-right: 16px;
  }

  @media (min-width: 1920px) {
    padding-right: 16px;
  }
}


@mixin paddingRight8 {
  @media (min-width: 320px) {
    padding-right: 8px;
  }

  @media (min-width: 960px) {
    padding-right: 8px;
  }

  @media (min-width: 1280px) {
    padding-right: 8px;
  }

  @media (min-width: 1440px) {
    padding-right: 8px;
  }

  @media (min-width: 1920px) {
    padding-right: 8px;
  }
}


@mixin paddingRight4 {
  @media (min-width: 320px) {
    padding-right: 4px;
  }

  @media (min-width: 960px) {
    padding-right: 4px;
  }

  @media (min-width: 1280px) {
    padding-right: 4px;
  }

  @media (min-width: 1440px) {
    padding-right: 4px;
  }

  @media (min-width: 1920px) {
    padding-right: 4px;
  }
}









@mixin paddingLeft512 {
  @media (min-width: 320px) {
    padding-left: 128px;
  }

  @media (min-width: 960px) {
    padding-left: 192px;
  }

  @media (min-width: 1280px) {
    padding-left: 192px;
  }

  @media (min-width: 1440px) {
    padding-left: 256px;
  }

  @media (min-width: 1920px) {
    padding-left: 512px;
  }
}


@mixin paddingLeft256 {
  @media (min-width: 320px) {
    padding-left: 96px;
  }

  @media (min-width: 960px) {
    padding-left: 128px;
  }

  @media (min-width: 1280px) {
    padding-left: 128px;
  }

  @media (min-width: 1440px) {
    padding-left: 192px;
  }

  @media (min-width: 1920px) {
    padding-left: 256px;
  }
}


@mixin paddingLeft128 {
  @media (min-width: 320px) {
    padding-left: 64px;
  }

  @media (min-width: 960px) {
    padding-left: 80px;
  }

  @media (min-width: 1280px) {
    padding-left: 80px;
  }

  @media (min-width: 1440px) {
    padding-left: 96px;
  }

  @media (min-width: 1920px) {
    padding-left: 128px;
  }
}


@mixin paddingLeft96 {
  @media (min-width: 320px) {
    padding-left: 32px;
  }

  @media (min-width: 960px) {
    padding-left: 64px;
  }

  @media (min-width: 1280px) {
    padding-left: 64px;
  }

  @media (min-width: 1440px) {
    padding-left: 80px;
  }

  @media (min-width: 1920px) {
    padding-left: 96px;
  }
}


@mixin paddingLeft80 {
  @media (min-width: 320px) {
    padding-left: 32px;
  }

  @media (min-width: 960px) {
    padding-left: 48px;
  }

  @media (min-width: 1280px) {
    padding-left: 48px;
  }

  @media (min-width: 1440px) {
    padding-left: 64px;
  }

  @media (min-width: 1920px) {
    padding-left: 80px;
  }
}


@mixin paddingLeft64 {
  @media (min-width: 320px) {
    padding-left: 32px;
  }

  @media (min-width: 960px) {
    padding-left: 36px;
  }

  @media (min-width: 1280px) {
    padding-left: 36px;
  }

  @media (min-width: 1440px) {
    padding-left: 48px;
  }

  @media (min-width: 1920px) {
    padding-left: 64px;
  }
}


@mixin paddingLeft48 {
  @media (min-width: 320px) {
    padding-left: 24px;
  }

  @media (min-width: 960px) {
    padding-left: 32px;
  }

  @media (min-width: 1280px) {
    padding-left: 32px;
  }

  @media (min-width: 1440px) {
    padding-left: 36px;
  }

  @media (min-width: 1920px) {
    padding-left: 48px;
  }
}


@mixin paddingLeft32 {
  @media (min-width: 320px) {
    padding-left: 24px;
  }

  @media (min-width: 960px) {
    padding-left: 24px;
  }

  @media (min-width: 1280px) {
    padding-left: 24px;
  }

  @media (min-width: 1440px) {
    padding-left: 32px;
  }

  @media (min-width: 1920px) {
    padding-left: 32px;
  }
}


@mixin paddingLeft24 {
  @media (min-width: 320px) {
    padding-left: 16px;
  }

  @media (min-width: 960px) {
    padding-left: 18px;
  }

  @media (min-width: 1280px) {
    padding-left: 18px;
  }

  @media (min-width: 1440px) {
    padding-left: 24px;
  }

  @media (min-width: 1920px) {
    padding-left: 54px;
  }
}


@mixin paddingLeft16 {
  @media (min-width: 320px) {
    padding-left: 16px;
  }

  @media (min-width: 960px) {
    padding-left: 16px;
  }

  @media (min-width: 1280px) {
    padding-left: 16px;
  }

  @media (min-width: 1440px) {
    padding-left: 16px;
  }

  @media (min-width: 1920px) {
    padding-left: 16px;
  }
}


@mixin paddingLeft8 {
  @media (min-width: 320px) {
    padding-left: 8px;
  }

  @media (min-width: 960px) {
    padding-left: 8px;
  }

  @media (min-width: 1280px) {
    padding-left: 8px;
  }

  @media (min-width: 1440px) {
    padding-left: 8px;
  }

  @media (min-width: 1920px) {
    padding-left: 8px;
  }
}


@mixin paddingLeft4 {
  @media (min-width: 320px) {
    padding-left: 4px;
  }

  @media (min-width: 960px) {
    padding-left: 4px;
  }

  @media (min-width: 1280px) {
    padding-left: 4px;
  }

  @media (min-width: 1440px) {
    padding-left: 4px;
  }

  @media (min-width: 1920px) {
    padding-left: 4px;
  }
}