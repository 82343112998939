@mixin animated($duraction: 0.3s) {
	transition: $duraction;
}
@mixin transform($string) {
	transform: $string;
}

.title__glitch {
    position: relative;
    z-index: 5;
    @include animated;
    &:before,
    &:after {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: inherit;
        @include animated;
        clip: rect(0 0 0 0);
    }

    

    &:hover {
      &:before,
      &:after {
        // animation: glitch-anim 2.5s infinite linear alternate-reverse;
      }

      &:before {
        left: 2px;
        text-shadow: -2px 0 #00FFFF;
        clip: rect(24px, 550px, 90px, 0);
        animation: glitch-anim-2 3s infinite linear alternate-reverse;
    }

    &:after {
        left: -2px;
        text-shadow: -2px 0 #662d90;
        clip: rect(85px, 550px, 140px, 0);
        animation: glitch-anim 2.5s infinite linear alternate-reverse; 
    }


    }
}



@keyframes glitch-anim {
  0% {
    clip: rect(100px, 9999px, 121px, 0);
  }
  4.166666666666666% {
    clip: rect(30px, 9999px, 82px, 0);
  }
  8.333333333333332% {
    clip: rect(99px, 9999px, 90px, 0);
  }
  12.5% {
    clip: rect(34px, 9999px, 39px, 0);
  }
  16.666666666666664% {
    clip: rect(45px, 9999px, 27px, 0);
  }
  20.833333333333336% {
    clip: rect(0px, 9999px, 95px, 0);
  }
  25% {
    clip: rect(58px, 9999px, 31px, 0);
  }
  29.166666666666668% {
    clip: rect(46px, 9999px, 148px, 0);
  }
  33.33333333333333% {
    clip: rect(139px, 9999px, 3px, 0);
  }
  37.5% {
    clip: rect(44px, 9999px, 78px, 0);
  }
  41.66666666666667% {
    clip: rect(16px, 9999px, 130px, 0);
  }
  45.83333333333333% {
    clip: rect(47px, 9999px, 81px, 0);
  }
  50% {
    clip: rect(60px, 9999px, 35px, 0);
  }
  54.166666666666664% {
    clip: rect(77px, 9999px, 142px, 0);
  }
  58.333333333333336% {
    clip: rect(41px, 9999px, 76px, 0);
  }
  62.5% {
    clip: rect(143px, 9999px, 71px, 0);
  }
  66.66666666666666% {
    clip: rect(105px, 9999px, 122px, 0);
  }
  70.83333333333334% {
    clip: rect(101px, 9999px, 40px, 0);
  }
  75% {
    clip: rect(19px, 9999px, 107px, 0);
  }
  79.16666666666666% {
    clip: rect(109px, 9999px, 39px, 0);
  }
  83.33333333333334% {
    clip: rect(129px, 9999px, 79px, 0);
  }
  87.5% {
    clip: rect(65px, 9999px, 73px, 0);
  }
  91.66666666666666% {
    clip: rect(84px, 9999px, 129px, 0);
  }
  95.83333333333334% {
    clip: rect(18px, 9999px, 131px, 0);
  }
  100% {
    clip: rect(107px, 9999px, 109px, 0);
  }
}

@keyframes glitch-anim-2 {
  6.666666666666667% {
    clip: rect(74px, 9999px, 2px, 0);
  }
  10% {
    clip: rect(84px, 9999px, 16px, 0);
  }
  13.333333333333334% {
    clip: rect(33px, 9999px, 27px, 0);
  }
  16.666666666666664% {
    clip: rect(59px, 9999px, 73px, 0);
  }
  20% {
    clip: rect(122px, 9999px, 111px, 0);
  }
  23.333333333333332% {
    clip: rect(2px, 9999px, 84px, 0);
  }
  26.666666666666668% {
    clip: rect(56px, 9999px, 92px, 0);
  }
  30% {
    clip: rect(101px, 9999px, 128px, 0);
  }
  33.33333333333333% {
    clip: rect(36px, 9999px, 76px, 0);
  }
  36.666666666666664% {
    clip: rect(65px, 9999px, 123px, 0);
  }
  40% {
    clip: rect(23px, 9999px, 23px, 0);
  }
  43.333333333333336% {
    clip: rect(79px, 9999px, 24px, 0);
  }
  46.666666666666664% {
    clip: rect(129px, 9999px, 101px, 0);
  }
  50% {
    clip: rect(132px, 9999px, 131px, 0);
  }
  53.333333333333336% {
    clip: rect(106px, 9999px, 4px, 0);
  }
  56.666666666666664% {
    clip: rect(63px, 9999px, 139px, 0);
  }
  60% {
    clip: rect(87px, 9999px, 77px, 0);
  }
  63.33333333333333% {
    clip: rect(14px, 9999px, 13px, 0);
  }
  66.66666666666666% {
    clip: rect(77px, 9999px, 42px, 0);
  }
  70% {
    clip: rect(131px, 9999px, 64px, 0);
  }
  73.33333333333333% {
    clip: rect(87px, 9999px, 34px, 0);
  }
  76.66666666666667% {
    clip: rect(40px, 9999px, 46px, 0);
  }
  80% {
    clip: rect(39px, 9999px, 12px, 0);
  }
  83.33333333333334% {
    clip: rect(97px, 9999px, 117px, 0);
  }
  86.66666666666667% {
    clip: rect(26px, 9999px, 106px, 0);
  }
  90% {
    clip: rect(75px, 9999px, 20px, 0);
  }
  93.33333333333333% {
    clip: rect(40px, 9999px, 6px, 0);
  }
  96.66666666666667% {
    clip: rect(71px, 9999px, 10px, 0);
  }
  100% {
    clip: rect(92px, 9999px, 48px, 0);
  }
}





