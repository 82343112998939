@import "../base/mixins";

.arena-game-preview{
  border: 1px solid #00FFFF;
  @include paddingTop64();
  @include paddingBottom64();
  @include paddingLeft48();
  @include paddingRight48();
  //padding: 64px 48px;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  position: relative;
  z-index: 2;
  text-decoration: none!important;

  @include at-xs{
    height: 500px;
  }

  @include at-md{
    height: 695px;
  }

  &:hover {
    .arena-game-preview__title, .arena-game-preview__description {
      color: #160F66;
      z-index: 2;
    }
  }
}

.arena-game-preview__title{
  font-weight: bold;
  @include font48();
  color: #FFFFFF;
}

.arena-game-preview__description{
  @include font112();
  color: #00FFFF;
}