@import "../base/mixins";

.link-with-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  text-decoration: none !important;
}

.link-with-arrow__text {
  @include marginRight32();
  font-weight: 300;
  @include font18();
  text-transform: uppercase;
  color: #FFFFFF;
}

.link-with-arrow__arrow {

}

.link-with-arrow--back {
  .link-with-arrow__text {
    margin-right: 0;
  }

  .link-with-arrow__arrow {
    @include marginRight32();
  }
}