@import "../base/mixins";


.container {
  width: 100%;
  padding: 0 40px;
  position: relative;

  @include at-xs {
    padding: 0 32px;
  }

  @include at-sm {
    padding: 0 40px;
  }
}

header {
  position: fixed;
  top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: 40px;
  left: 40px;
  z-index: 10;

  @include at-xs {
    left: 32px;
    right: 32px;
    top: 32px;
  }

  @include at-sm {
    left: 40px;
    right: 40px;
    top: 30px;
  }

  @include at-md {
    top: 30px;
  }

  @include at-lg {
    top: 70px;
  }

  .base-logo {
    @include marginRight32();


    @include at-xs {
      width: 88px;
    }

    @include at-sm {
      width: 110px;
    }

    @include at-fh {
      width: 128px;
    }

    img {
      width: 100%;
    }
  }

  .base-button-transparent {
    margin-right: 0;
    margin-top: -8px;
  }

  .base-link {
    @include marginLeft32();
  }
}


.header__menu {
  display: flex;
  align-items: center;
  transition: 0.3s linear all;

  @include at-xs {
    display: none;
  }

  @include at-md {
    display: flex;
  }
}

.header-armor-button {
  @include at-xs {
    display: none;

    .base-button__small-blue {
      min-width: 170px;
      line-height: 45px;
      height: 45px;
    }
  }

  @include at-md {
    display: block;
  }

  @include at-fh{
    .base-button__small-blue {
      min-width: 190px;
      line-height: 47px;
      height: 47px;
    }
  }
}

.mobile-menu {
  @include at-xs {
    display: flex;
  }

  @include at-md {
    display: none;
  }
}