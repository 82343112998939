@import "../base/mixins";

.main-cybersport {
  //margin-top: 165px;
  @include marginTop128();
}

.main-cybersport__content {
  @include paddingTop128();
}

.main-cybersport__row-1 {
  display: grid;
  grid-template-columns: 5fr 1fr 3fr 3fr;
  grid-gap: 40px;

  @include at-xs {
    display: block;
    .empty {
      display: none;
    }
  }

  @include at-sm {
    display: grid;
    grid-template-columns: 6fr 6fr;
  }

  @include at-lg {
    grid-template-columns: 5fr 1fr 3fr 3fr;

    .empty {
      display: block;
    }
  }
}

.main-cybersport__row-2 {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 3fr 3fr;
  grid-gap: 40px;
  margin-top: 180px;

  @include at-xs {
    margin-top: 0;
  }

  @include at-md {
    margin-top: 180px;
  }

  @include at-xs {
    display: block;
    .empty {
      display: none;
    }
  }

  @include at-sm {
    display: grid;
    grid-template-columns: 6fr 6fr;
  }

  @include at-lg {
    grid-template-columns: 2fr 3fr 1fr 3fr 3fr;

    .empty {
      display: block;
    }
  }
}

.main-cybersport__row-3 {
  display: grid;
  grid-template-columns: 2fr 3fr 7fr;
  grid-gap: 40px;
  margin-top: 180px;
}

.main-cybersport__description {
  font-weight: 200;
  @include font48();
  color: #FFFFFF;

  @include at-xs {
    margin-bottom: 40px;
  }

  @include at-sm {
    margin-bottom: 0;
  }

  span {
    font-weight: bold;
  }
}