@import "../base/mixins";

.popup-content{
  overflow: hidden;
}


.popup-wrapper{
  position: fixed;
  top: 10%;
  left: calc((100vw - 40px) / 12);
  right: calc((100vw - 40px) / 12);
  background: #FFFFFF;
  //height: 80vh;
  z-index: 20;
  overflow: auto;
  @include paddingBottom128();
  transition: all 0.3s ease;

  @include at-xs{
    left: 0;
    right: 0;
  }

  @include at-sm{
    left: calc((100vw - 40px) / 12);
    right: calc((100vw - 40px) / 12);
  }
}

.popup-wrapper__close-button{
  position: fixed;
  top: 16%;
  right: calc(((100vw - 40px) / 12) - 96px);
  cursor: pointer;
  transition: all 0.1s linear;
  z-index: 20;

  @include at-xs{
    right: 32px;
    top: calc(10% + 32px);
  }

  @include at-sm{
    top: 16%;
    right: calc(((100vw - 40px) / 12) - 96px);
  }

  svg{
    stroke: #00FFFF;
  }

  &:hover{
    transform: translate(6px, -6px);
    svg{
      stroke: #A7FFFF;
      filter: drop-shadow(0px 0px 20px rgba(0, 255, 255, 0.302475));
    }
  }

  &:active{
    transform: translate(0, 0);

    svg{
      stroke: white;
      filter: none;
    }
  }
}

.popup-wrapper__order-popup {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 5fr;

  @include at-xs{
    grid-template-columns: 1fr 11fr;
  }

  @include at-md{
    grid-gap: 40px;
    grid-template-columns: 1fr 3fr 1fr 5fr;
  }
}