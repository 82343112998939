@import "../base/mixins";

.arena-games {
  display: flex;
  align-items: center;
  position: relative;

  @include at-sm {
    height: 600px;
    padding-bottom: 40px;
  }

  @include at-md {
    height: 100vh;
    padding-bottom: 0;
  }
}

.arena-games__title-block {
  display: grid;
  grid-template-columns: 4fr 2fr 5fr 1fr;
  grid-gap: 40px;
  position: relative;

  @include at-xs {
    grid-template-columns: 1fr;

    .empty {
      display: none;
    }
  }

  @include at-sm {
    grid-template-columns: 4fr 5fr 1fr;
    padding: 92px 0;
  }

  @include at-md {
    grid-template-columns: 4fr 2fr 5fr 1fr;

    .empty {
      display: block;
    }
  }
}

.arena-games__title-description {
  font-weight: 200;
  @include font32();
  color: #FFFFFF;
}

.arena-games__previews-list {
  display: grid;

  grid-gap: 40px;
  position: relative;
  @include paddingTop96();

  @include at-xs {
    grid-template-columns: 1fr;
    margin-bottom: 48px;

    .empty {
      display: none;
    }
  }

  @include at-sm {
    margin-bottom: 0;
    grid-template-columns: 3fr 3fr;
  }

  @include at-md {
    grid-template-columns: 2fr 3fr 3fr 3fr 1fr;

    .empty {
      display: block;
    }
  }
}

.arena-games__link-block {
  display: flex;
  align-items: center;
  position: relative;

  @include at-md {
    margin-right: -15px;
    justify-content: flex-end;
  }
}

.arena-games__popular_games {
  pointer-events: none;
  z-index: 1;
}

.arena-games__popular_games-title {
  @include marginBottom32();
  display: flex;
  align-items: flex-start;
}

.arena-games__popular_games-title-top {
  @include font96();
  color: #00FFFF;
  @include marginRight24();
}

.arena-games__popular_games-title-all {
  @include font18();
  text-transform: uppercase;
  color: #00FFFF;
  @include marginTop24();
}

.arena-games__popular_games-list {
  font-weight: 300;
  @include font48();
  color: #00FFFF;
}

.arena-games__popular_game {
  display: inline-block;
}

.arena-games__popular_game-separator {
  display: inline-block;
  margin: 0 3px;
}

.arena-games__title-block-wrapper {
  width: 100%;
  height: 100%;

  .decoration-polygon-circle {
    top: -33vh;
    right: -50vw;

    @include at-sm {
      right: -90vw;
    }

    @include at-md {
      right: -70vw;
    }

    @include at-lg {
      right: -60vw;
    }

    @include at-fh {
      right: -50vw;
    }
  }
}

.arena-games__title-block-decoration-block {
  position: absolute;
  width: 100%;
  height: 100%;
  grid-template-columns: 2fr 9fr 1fr;
  grid-gap: 40px;
  display: none;

  @include at-sm {
    display: grid;
  }
}

.arena-games__title-block-decoration {
  border: 1px solid #00FFFF;
  pointer-events: none;
  z-index: 1;
  position: relative;
}