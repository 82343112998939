@import "../blocks/card_preview.scss";
@import "../base/mixins";

.command-preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #00FFFF;
  @include paddingTop64();
  @include paddingBottom64();
  @include paddingLeft48();
  @include paddingRight48();
  text-decoration: none !important;
  min-width: 0;

  @include at-xs{
    height: 300px;
  }

  @include at-md {
    height: 500px;
  }

  @include at-lg {
    height: 585px;
  }

  @include at-fh{
    height: 695px;
  }

  &:hover {
    .command-preview__title,
    .command-preview__description,
    .command-preview__game-text, .command-preview__date {
      color: #160F66;
    }

    .command-preview__game-icon svg path {
      fill: #160F66;
    }
  }
}

.command-preview__game {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.command-preview__game-icon {
  @include marginRight32();

  svg path {
    fill: #00FFFF;
    transition: fill 0.2s linear;
  }
}

.command-preview__game-text {
  @include font18();
  align-items: center;
  text-transform: uppercase;
  color: #00FFFF;
  transition: color 0.2s linear;
}

.command-preview__content {
  position: relative;
  z-index: 2;
}

.command-preview__description {
  @include font16();
  color: #00FFFF;
  transition: color 0.2s linear;

  p {
    margin: 0;
  }
}

.command-preview__title {
  @include font32();
  color: #00FFFF;
  transition: color 0.2s linear;
  @include marginBottom32();
}

.command-preview__date {
  @include font16();
  text-transform: uppercase;
  color: #FFFFFF;
  @include marginBottom24();
}