.pageSliderLeft-enter {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(100%, 0, 0);
}
.pageSliderLeft-enter.pageSliderLeft-enter-active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(0, 0, 0);
    transition: all 600ms;
}
.pageSliderLeft-exit {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(0, 0, 0);
}
.pageSliderLeft-exit.pageSliderLeft-exit-active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(100%, 0, 0);
    transition: all 600ms;
}







.pageSliderRight-enter {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(-100%, 0, 0);
}
.pageSliderRight-enter.pageSliderRight-enter-active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(0, 0, 0);
    transition: all 600ms;
}
.pageSliderRight-exit {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(0, 0, 0);
}
.pageSliderRight-exit.pageSliderRight-exit-active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(-100%, 0, 0);
    transition: all 600ms;
}









.alert-enter {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(0, 100%, 0);
    z-index: 20;
}
.alert-enter-active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(0, 0, 0);
    transition: all 600ms;
    z-index: 20;
}
.alert-exit {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(0, 0, 0);
    z-index: 20;
}
.alert-exit-active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate3d(0, 100%, 0);
    transition: all 600ms;
    z-index: 20;
}
