@import "../base/mixins";

.success-modal__wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-modal {
  background: white;
  position: relative;

  @include at-md {
    width: 50vw;
  }
}

.success-modal__close-button {
  position: absolute;
  top: 0;
  right: -110px;
  cursor: pointer;

  svg {
    stroke: #00FFFF;
  }

  &:hover {
    transform: translate(6px, -6px);

    svg {
      stroke: #A7FFFF;
      filter: drop-shadow(0px 0px 20px rgba(0, 255, 255, 0.302475));
    }
  }

  &:active {
    transform: translate(0, 0);

    svg {
      stroke: white;
      filter: none;
    }
  }
}

.success-modal__content {
  @include paddingTop128();
  @include paddingBottom128();
  padding-right: calc(100vw / 12);
  padding-left: calc(100vw / 12);
}

.success-modal__title {
  @include font48();
  @include marginBottom48();
  color: #160F66;
}

.success-modal__description {
  @include font16();
  @include marginBottom128();
  color: #00043A;
}

.success-modal__button-block {

}