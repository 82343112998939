@import '../base/mixins';

.games-page {

}

.games-filters-wrapper {
  display: grid;

  @include marginTop256();
  @include marginBottom64();
  position: relative;

  @include at-xs {
    grid-template-columns: 1fr;
  }

  @include at-md {
    grid-gap: 40px;
    grid-template-columns: 2fr 9fr 1fr;
  }
}

.games-filters-wrapper--bottom {
  margin-top: 0 !important;
}

.games-filters {
  display: flex;
  flex-wrap: wrap;
  z-index: 1;

  .base-button-transparent {
    height: 32px;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.680556px;
    margin-left: -10px;
    @include marginTop16();
    margin-right: 24px;
  }

  .base-link {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.680556px;
    margin-right: 32px;
    text-transform: none;
    @include marginTop24();
  }

  .base-button-transparent--decoration-right:before {
    right: -7px;
  }

  .base-button-transparent--decoration-right:after {
    height: 26.5%;
  }

  .base-button-transparent--decoration-left:before {
    left: -4.5px;
  }

  .base-button-transparent--decoration-left:after {
    height: 82%;
  }


}

.games-list-wrapper {
  display: grid;
  grid-template-columns: 2fr 3fr 6fr 1fr;
  grid-gap: 40px;
  @include paddingBottom80();
  @include marginBottom80();
  position: relative;

  @include at-xs {
    grid-template-columns: 1fr;
  }

  @include at-md {
    grid-template-columns: 2fr 3fr 6fr 1fr;
  }
}

.games-list__aside-menu {

}

.games-list__aside-menu-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;

  &.active {
    cursor: auto;

    .games-list__aside-menu-link-text {
      color: white;
      border-bottom: none;
    }

    .games-list__aside-menu-link-icon {
      display: none;
    }
  }

  &.disable {
    .games-list__aside-menu-link-icon {
      transform: rotate(180deg);
    }
  }
}

.games-list__aside-menu-link-text {
  font-weight: bold;
  @include font48();
  color: #00FFFF;
  @include marginRight8();
  border-bottom: 1px dashed #00FFFF;
  @include paddingBottom8();
}

.games-list__aside-menu-link-icon {

}

.games-list {
  &.disable {
    display: none;
  }
}

.games-list-content{
  display: grid;
  grid-template-columns: 3fr 3fr;
  grid-gap: 40px;
  z-index: 1;

  @include at-xs {
    grid-template-columns: 3fr;
  }

  @include at-sm {
    grid-template-columns: 3fr 3fr;
  }

  @include at-md {
    grid-template-columns: 3fr 3fr;
  }
}

.game-name {
  @include font24();
  color: #00FFFF;
  @include marginBottom48();

  span{
    color: white;
    font-weight: bold;
  }
}