@import "../base/mixins";

.main-map {
  position: relative;
  width: 100%;
  height: 100vh;

  @include at-xs {
    height: auto;
  }

  //@include at-sm {
  //  margin-top: 120px;
  //  display: grid;
  //  grid-template-columns: 1fr 2fr;
  //}

  @include at-sm {
    height: 550px;
    display: block;
  }

  @include at-md {
    height: 100vh;
    min-width: 750px;
  }

  @include at-fh {
    height: 100vh;
    min-height: 1020px;
    display: block;
  }

  .big-title {
    display: inline-block;
    @include at-xs {
      margin-top: 50px;
    }

    @include at-md {
      margin-top: 100px;
    }

    @include at-lg {
      margin-top: 0;
    }

    @include at-fh {
      margin-top: 100px;
    }
  }
}

.main-map__map-block {
  .container {
    @include at-xs {
      padding: 48px 0;
    }

    @include at-sm {
      padding: 0 32px;
    }
  }

  @include at-sm {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.main-map__map-wrapper {
  display: grid;

  grid-gap: 40px;

  @include at-xs {
    .empty {
      display: none;
    }
  }

  @include at-sm {
    grid-template-columns: 3fr 9fr;

    .empty {
      display: block;
    }
  }

  @include at-fh {
    grid-template-columns: 3fr 8fr 1fr;

    .empty {
      display: block;
    }
  }
}


.main-map__map {
  width: 100%;
  display: flex;
  align-items: center;

  @include at-lg {
    height: 100vh;
  }

  //@include at-lg {
  //  padding: 5% 0;
  //}
}

.main-map__map-mask {
  position: relative;
  width: 100%;
  height: 100%;

  @include at-xs {
    height: 410px;
  }

  @include at-sm {
    height: 63vw;
  }

  @include at-sm {
    height: 47vw;
  }

  @include at-fh {
    height: 40vw;
  }
}

.main-map__map-border {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: #00FFFF;
  mask: url(../../images/google_map_mask.svg#mapMask);
  mask-size: cover;

  @include at-xs {
    display: none;
  }

  @include at-sm {
    display: block;
  }
}

.main-map__map-container {
  width: 100%;
  height: 100%;

  @include at-sm {
    mask: url(../../images/google_map_mask.svg#mapMask);
    mask-size: cover;
  }
}

.main-map__map-video {
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.main-map__map-content {
  width: 100%;
  height: 100%;
}

.main-map__content {
  position: relative;

  @include at-lg {
    padding-top: 124px;
  }
}

.main-map__content--mobile {
  padding-bottom: 80px;

  @include at-xs {
    display: block;
  }

  @include at-sm {
    display: none;
  }

  .main-map__phone {
    padding-top: 0;
  }

  .main-map__help-preview {
    padding-top: 0;
  }

  .main-map__social-block {
    margin-top: 0;
  }

  .main-map__help-block {
    grid-row-gap: 16px;
  }

  .main-map__help-text {
    margin-bottom: 16px;
  }
}

.main-map__content--pc {
  @include at-xs {
    display: none;
  }

  @include at-sm {
    display: block;
  }
}

.main-map__content-title {
  transition: transform 0.3s linear;
}

.main-map__content-title--transform {

  @include at-md {
    transform: translateX(-10%);
  }
}

.main-map__phone {
  font-weight: bold;
  @include font48();
  color: #FFFFFF;
  @include paddingTop128();

  a {
    text-decoration: none !important;
    color: white;
    position: relative;
    z-index: 1;
  }
}

.main-map__help-block {
  display: grid;
  grid-gap: 40px;
  align-items: flex-start;

  @include at-xs {
    .empty {
      display: none;
    }

    grid-template-columns: 1fr;
  }

  @include at-sm {
    .empty {
      display: block;
    }
    grid-template-columns: 3fr 3fr 6fr;
  }

  @include at-md {
    grid-template-columns: 2fr 3fr 7fr;
  }

  @include at-fh {
    .empty {
      display: block;
    }

    grid-template-columns: 2fr 2fr 8fr;
  }
}

.main-map__social-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  @include paddingTop32();
  @include marginTop8();
  transition: transform 0.3s linear;
  max-width: 500px;
}

.main-map__social-link {

}

.main-map__help-preview {
  @include paddingTop32();
}

.main-map__help-text {
  font-weight: 200;
  @include font32();
  color: #00FFFF;
  @include marginBottom64();
  position: relative;
  z-index: 1;
  transition: transform 0.3s linear;
}

.main-map__help-text--transform {
  @include at-md {
    transform: translateX(calc(-100% - 32px));
  }
}

.main-map__help-button {
  display: flex;
  align-items: center;
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  position: relative;
  z-index: 1;
  cursor: pointer;

  &:hover {
    .main-map__help-button-icon-polygon {
      transform: rotate(-10deg);
    }
  }
}

.main-map__help-button-icon {
  @include marginRight16();
  position: relative;
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include at-xs {
    width: 35px;
    height: 35px;
  }

  @include at-md {
    width: 48px;
    height: 48px;
  }

  @include at-lg {
    width: 68px;
    height: 68px;
  }

  @include at-fh {
    width: 96px;
    height: 96px;
  }
}

.main-map__help-button-icon-polygon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  img {
    width: 100%;
    height: auto;
  }
}

.main-map__help-button-icon-play {
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    @include at-xs {
      width: 25px;
      height: auto;
    }

    @include at-md {
      width: 30px;
      height: auto;
    }

    @include at-lg {
      width: auto;
      height: auto;
    }
  }

  .main-map__help-button-icon-play {
    margin-left: 6px;

    @include at-xs {
      width: 10px;
      height: auto;
      margin-left: 3px;
    }

    @include at-md {
      width: 13px;
      height: auto;
      margin-left: 4px;
    }

    @include at-lg {
      width: auto;
      height: auto;
      margin-left: 6px;
    }
  }

  .main-map__help-button-icon-play--play {
    margin-left: 6px;

    @include at-xs {
      width: 10px;
      height: auto;
      margin-left: 3px;
    }

    @include at-md {
      width: 13px;
      height: auto;
      margin-left: 4px;
    }

    @include at-lg {
      width: auto;
      height: auto;
      margin-left: 6px;
    }
  }
}

.main-map__help-button-text {
  font-weight: 300;
  @include font18();
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: left;

  @include at-xs {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.875px;
  }

  @include at-md {
    font-size: 16px;
    line-height: 22px;
  }

  @include at-lg {
    font-size: 18px;
    line-height: 24px;
  }
}

a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}