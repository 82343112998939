@import "../base/mixins";

.command-page {
  color: #00043A;
  @include paddingTop96();
  @include paddingBottom96();
  padding-right: 0;
  padding-left: 0;
}

.command-page__header {
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 1fr;
  grid-gap: 40px;
  flex-grow: 1;
}

.command-page__logo {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 40px;
  flex-grow: 1;

  img {
    max-width: 100%;
  }
}

.command-page__game-icon {
  @include marginRight8();
  display: flex;
  align-items: center;

  svg path {
    fill: #160F66;
  }
}

.command-page__game {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.command-page__top-content {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 2fr 2fr 1fr;
  grid-gap: 40px;
  flex-grow: 1;
  @include marginTop32();
}

.command-page__name {
  @include font48();
  color: #160F66;
}

.command-page__description {
  @include font16();
  color: #00043A;
}

.command-page__content-block {
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 1fr;
  grid-gap: 40px;
  flex-grow: 1;
  @include marginTop128();
}

.command-page__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  flex-grow: 1;
}

.command-page__content-title {
  font-weight: 500;
  @include font24();
  text-transform: uppercase;
  color: #160F66;
}

.command-page__content-award {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include marginBottom24();
}

.command-award__image {
  @include marginBottom32();
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background: #D8D8D8;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.command-award__title {
  @include font16();
  text-transform: uppercase;
  color: #00043A;

  span {
    font-weight: bold;
  }
}

.command-page__content-team-block {
  display: grid;
  grid-template-columns: 1fr 2fr 6fr 1fr;
  grid-gap: 40px;
  flex-grow: 1;
  @include marginTop128();
}

.command-page__content-team {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  flex-grow: 1;
}

.command-page__team {
  @include marginBottom24();
}

.command-team__image:before,
.command-team__image:after,
.command-team__image--top:before,
.command-team__image--top:after {
  background: #D8D8D8;
}

.command-team__image--top {
  border-color: #D8D8D8
}

.command-team__image {
  position: relative;
  width: 96px;
  height: 96px;
  overflow: hidden;
  z-index: 10;
  @include marginBottom32();

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 1px;
    height: 40px;
    margin-top: -20px;
    z-index: 1;
  }

  &:after {
    left: auto;
    right: 0;
    margin-top: -20px;
    height: 40px;
  }
}

.command-team__image--top {
  position: absolute;
  top: 0px;
  width: 96px;
  height: 96px;
  background: #D8D8D8;
  border-width: 1px;
  border-style: solid;
  transform: rotate(45deg);
}

.command-team__name, .command-team__title {
  @include font16();
  text-transform: uppercase;

  color: #00043A;
}

.command-team__name {
  font-weight: bold;
}

