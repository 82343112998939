@import "../blocks/card_preview.scss";
@import "../base/mixins";

.event-preview {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #00FFFF;
  text-decoration: none !important;
  position: relative;
  //padding: 32px 24px;
  @include paddingTop64();
  @include paddingBottom64();
  @include paddingRight48();
  @include paddingLeft48();

  @include at-xs{
    height: 100vw;
  }

  @include at-sm{
    height: 50vw;
  }

  @include at-md{
    height: 36vw;
  }

  &.not-hovering .event-preview__mask svg {
    transform: scale3d(0, 0, 0) !important;
  }

  &:hover {
    .event-preview__date-block,
    .event-preview__title,
    .event-preview__game-text {
      color: #160F66;
    }

    .event-preview__game-icon svg path {
      fill: #160F66;
    }

    .event-preview__date-month--detail {
      transform: translateX(-33%);
    }

    .event-preview__mask {
      opacity: 1;

      &::before {
        transform: scale3d(1, 1, 1);
      }

      svg {
        transform: scale3d(1.1, 1.15, 1.1);
      }
    }

    //.event-preview__content-container {
    //  overflow: visible;
    //}

    .event-preview__date-month-wrapper {
      svg {
        text {
          fill: #160F66;
        }
      }
    }
  }
}

.event-preview--complete {
  border: 1px solid white;

  .event-preview__game-icon {
    svg path {
      fill: white;
    }
  }

  .event-preview__game-text {
    color: white;
  }

  .event-preview__title {
    color: white;
  }

  .event-preview__date-block {
    color: white;
  }

  .event-preview__date-month-wrapper {
    svg {
      text {
        fill: white;
      }
    }
  }
}

.event-preview__content-container {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include paddingTop64();
  @include paddingBottom64();
  @include paddingRight48();
  @include paddingLeft48();
}

.event-preview__game {
  display: flex;
  align-items: center;
}

.event-preview__game-icon {
  @include marginRight16();

  svg path {
    fill: #00FFFF;
    transition: fill 0.2s linear;
  }
}

.event-preview__game-text {
  @include font18();
  align-items: center;
  text-transform: uppercase;
  color: #00FFFF;
  transition: color 0.2s linear;
}

.event-preview__title {
  @include font32();
  color: #00FFFF;
  @include marginBottom48();
  transition: color 0.2s linear;
}

.event-preview__date-block {
  @include font112();
  color: #00FFFF;
  transition: color 0.2s linear;
}

.event-preview__date-day {
  @include marginBottom96();
}

.event-preview__date-month {
  position: relative;
  width: 100%;


  @media (min-width: 320px) {
    height: 32px;
  }

  @media (min-width: 960px) {
    height: 48px;
  }

  @media (min-width: 1280px) {
    height: 64px;
  }

  @media (min-width: 1440px) {
    height: 96px;
  }

  @media (min-width: 1920px) {
    height: 112px;
  }
}

.event-preview__date-month-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(1.5);
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  transition: opacity 0.2s linear, transform 0.2s linear;

  svg {
    position: absolute;
    transform-origin: center;
    transition: opacity 0.2s linear, transform 0.2s linear;
    width: 100%;
    height: auto;

    text {
      fill: #00FFFF;
      font-weight: bold;
      text-transform: uppercase;
      transform-origin: center;

      @include at-xs{
        font-size: 25px;
      }

      @include at-sm{
        font-size: 28px;
      }

      @include at-md{
        font-size: 36px;
      }

      @include at-lg{
        font-size: 50px;
      }

      @include at-fh{
        font-size: 72px;
      }
    }
  }
}

.event-preview__date-month--detail {
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  transform: translateX(0);
  transition: transform 0.2s linear;
}

.event-preview__mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s linear;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    transform-origin: center;
    transform: scale3d(0.5, 0.5, 0.5);
    transition: transform 0.2s linear;
  }

  svg {
    position: absolute;
    transform-origin: center;
    transition: transform 0.2s linear;
  }
}

.event-preview__mask--path-1 {
  right: 0;
  top: 0;
}

.event-preview__mask--path-2 {
  right: 0;
  bottom: 0;
}

.event-preview__mask--path-3 {
  left: 0;
  bottom: 0;
}

.event-preview__mask--path-4 {
  left: 0;
  top: 0;
}

.event-preview__mask--path-5 {
  right: 0;
  bottom: 0;
}

.event-preview__mask--path-6 {
  right: -44px;
  top: 0;
}

.event-preview__mask--path-7 {
  left: 0;
  top: 0;
}

.event-preview__mask--path-8 {
  left: -56px;
  bottom: -10%;
}

.event-preview__mask--path-9 {
  bottom: -6px;
  left: -24px;
}

.event-preview__mask--path-10 {
  left: 0;
  top: 0;
}

.event-preview__mask--path-11 {
  top: -48px;
  right: 0;
}

.event-preview__mask--path-12 {
  right: -0;
  bottom: -24px;
}

.event-preview__mask--path-13 {
  bottom: 24px;
  left: 8px;
}

.event-preview__mask--path-14 {
  bottom: 18%;
  left: 0;
}