@keyframes text_position_animate {
  from { x: 25%; }
  to { x: 0%; }
}

.card-preview {
  position: relative;

  &.not-hovering .card-preview__mask {
    transform: scale(1);
    svg {
      path {
        transform: scale(1) !important;
      }
    }

    g, rect {
      transform-origin: center;
      transform: scale(1);
    }
  }



  &:hover {
    z-index: 5;
    background: white;

    .card-preview__mask {
      opacity: 1;
    }

    .event-preview__date-text-animation{
      animation: text_position_animate 1s;
    }
  }
}

.card-preview__mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s linear, transform 0.2s linear;
  transform: scale(1.5);


  svg {
    position: absolute;
    transform-origin: center;
    transition: opacity 0.2s linear, transform 0.2s linear;
    width: 100%;
    height: 100%;

    g, rect {
      //transition: opacity 0.2s linear, transform 0.2s linear;
      transform-origin: center;
      transform: scale(0.75);
    }

    path {
      transition: transform 0.2s linear;
      transform-origin: center;
    }
  }
}

.card-preview__mask-rect{
  transform: scale(1);
}