@import '../base/mixins';

.game-preview {
  display: flex;
  border: 1px solid #00FFFF;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  @include paddingBottom32();
  @include paddingTop32();
  @include paddingLeft48();
  @include paddingRight48();
  height: 300px;
  min-width: 0;
  text-decoration: none !important;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s linear;


  @include at-md {
    &:hover {
      z-index: 1;
      transform: scale(1.25);
      padding-right: 0!important;

      .game-preview__background-block {
        opacity: 1;
      }

      .game-preview__title {
        @include font48();
        font-weight: 600;
        transform: translateY(50px) scale(0.75) translateX(-5%);
      }

      .game-preview__title-icon {
        display: block;
      }
    }
  }
}

.game-preview__background-block {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s linear;
}

.game-preview__background-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.game-preview__background {
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.game-preview__background-mask {
  background: rgba(0, 4, 58, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.game-preview__text-wrapper {
  position: relative;
}

.game-preview__text {
  @include font32();
  color: #00FFFF;
  position: absolute;
  display: flex;
  align-items: flex-start;
  bottom: 0;
  right: 0;
  left: 0;
}

.game-preview__title-icon {
  @include marginRight16();
  display: none;
}

.game-preview__title {
  transition: all 0.3s linear;
  width: 100%;
}