@import '../base/mixins';

.all-service-preview {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 48px;
  height: 300px;
  min-width: 0;
  text-decoration: none !important;
  cursor: pointer;
  background: linear-gradient(19.23deg, #322DC5 6.12%, #21217A 91%);

  &:hover {
    .all-service-preview__hover-decoration{
      opacity: 1;
    }

    .all-service-preview__text {
      color: #2151A4;
    }
  }
}

.all-service-preview__hover-decoration{
  position: absolute;
  background: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s linear;
}


.all-service-preview__text {
  color: #FFFFFF;
  @include font32();
  position: relative;
  transition: color 0.3s linear;
}