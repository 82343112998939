@import '../base/mixins';

.statistic-block{
  @include marginTop128();
  @include marginBottom256();
  display: grid;

  grid-gap: 40px;

  @include at-xs{
    grid-template-columns: 1fr;
  }

  @include at-md{
    grid-template-columns: 4fr 4fr 4fr;
  }
}

.statistic-text-block{
  @include font96();
  text-transform: uppercase;
  color: #00FFFF;
  @include marginBottom256();
}

.statistic-preview{
  @include at-xs{
    margin-top: 64px;
  }

  @include at-md{
    margin-top: 0;
  }
}

.statistic-preview__title{
  @include font96();
  text-transform: uppercase;
  color: #00FFFF;
  @include marginBottom64();
}

.statistic-preview__description{
  @include font16();
  color: #00FFFF;

  @include at-sm{
    width: 50%;
    float: right;
  }
}

