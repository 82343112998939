@import '../base/mixins';

.main-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000028;
  z-index: 10;
  transform: translateX(-100vw);
  transition: 0.3s linear all;
  overflow: hidden;

  &.active {
    transform: translateX(0);
  }
}

.main-menu__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  @include paddingTop32();
  @include paddingBottom32();
  @include paddingLeft32();
  @include paddingRight32();
}

.main-menu__close-button {
  position: absolute;
  top: 84px;
  left: 30px;
  cursor: pointer;
  @include font96();
  text-transform: uppercase;
  color: #FFFFFF;
  //background: #000028;
  z-index: 1;

  @include at-xs{
    top: 93px;
    left: 24px;
  }

  @include at-sm{
    top: 92px;
  }

  @include at-md{
    top: 86px;
  }

  @include at-lg{
    top: 78px;
  }

  @include at-fh{
    top: 70px;
    left: 32px;
  }

  &:hover {
    text-shadow: 0px 0px 100px #00FFFF;
    color: #00FFFF;
  }
}

.main-menu__list {
  transform: translateY(180px);
  transition: 0.3s linear all;
}

.main-menu__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none !important;
}

.main-menu-link__text {
  text-transform: uppercase;
  color: #00FFFF;
  @include font96();
  transition: 0.1s linear all;
  text-decoration: none !important;

  span.disable {
    transition-delay: 0.3s;
    opacity: 0;
  }

  @include at-xs {
    width: 100%;
  }

  @include at-md {
    width: auto;
  }

  &:hover {
    color: #FFFFFF;
    text-shadow: 0px 0px 100px #00FFFF;

    @media (min-width: 320px) {
      transform: translateX(-24px);
    }

    @media (min-width: 960px) {
      transform: translateX(-25px);
    }

    @media (min-width: 1280px) {
      transform: translateX(-33px);
    }

    @media (min-width: 1440px) {
      transform: translateX(-43px);
    }

    @media (min-width: 1920px) {
      transform: translateX(-60px);
    }

    span.disable {
      transition-delay: 0s;
      opacity: 1;
    }

    span.disable_hover {
      //transition-delay: 0.3s;
      opacity: 0;
    }

    & + .main-menu-link__letter {
      transform: translateX(90px);
    }
  }
}

.main-menu-link__letter {
  font-weight: bold;
  font-size: 256px;
  line-height: 180px;
  letter-spacing: 12.4445px;
  text-transform: uppercase;
  color: #211F7F;
  transition: 0.2s linear all;
  transform: translateX(250px);
}