@import '../base/mixins';

.arena-map__wrapper {
  position: relative;

  @include at-xs {
    min-height: 100vh;
  }

  .arena-map__title {
    @include at-xs {
      padding: 0 32px;
    }

    @include at-sm {
      padding: 0 40px;
    }

    @include at-md {
      padding: 0;
    }
  }

  .container {
    @include at-xs {
      padding: 0;
    }

    @include at-sm {
      padding: 0;
    }

    @include at-md {
      padding: 0 40px;
    }
  }
}

.arena-map {
  display: grid;
  transition: transform 0.5s ease-in-out;
  @include marginBottom256();

  @include at-xs {
    grid-template-columns: 1fr;

    .empty {
      display: none;
    }
  }

  @include at-sm {
    grid-gap: 40px;
  }

  @include at-md {
    grid-template-columns: 3fr 9fr;

    .empty {
      display: block;
    }
  }

  @include at-lg {
    grid-template-columns: 3fr 8fr 1fr;
  }
}

.arena-map__zone-open {
  @include at-md {
    transform: translateX(-32vw);
  }

  @include at-lg {
    transform: translateX(-30vw);
  }

  .big-title {
    z-index: 0;
  }

  .arena-map__schema {
    opacity: 0;
    transition: 0.2s linear opacity;
    transform: translateX(-6vw);

    @include at-md {
      opacity: 1;
    }
  }

  .arena-map__zone-content {
    transform: translateX(6vw);
  }
}

.arena-map__title {
  @include marginTop128();
}

.arena-map__schema {
  position: relative;
  overflow-x: scroll;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  @include marginTop128();
  @include paddingTop64();

  @media (max-width: 1028px) {
    margin-top: 0;
    padding-top: 0;
  }

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  @include at-md {
    overflow-x: visible;
    overflow-y: visible;
  }


  svg {
    cursor: pointer;

    @include at-xs {
      width: 750px;
      height: auto;
    }

    @include at-sm {
      width: 1000px;
      height: auto;
    }

    @include at-md {
      cursor: default;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

}

.arena-map__schema-shadow {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(270deg, #000028 1.25%, rgba(0, 0, 40, 0) 10%), linear-gradient(90deg, #000028 1.25%, rgba(0, 0, 40, 0) 10%);
  pointer-events: none;

  @include at-md {
    display: none;
  }
}

.arena-map-interactive {

}

.arena-map-interactive__groups {
  &:hover {
    .arena-map-interactive__group, .arena-map-interactive__group--disabled {
      opacity: 0.5;
    }
  }

  .arena-map-interactive__group {
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .arena-map-interactive__group--info-opened {
    &:hover {
      opacity: 0.8;
    }
  }

  .arena-map-interactive__group--not-active {
    opacity: 0.5;
  }
}

.arena-map-interactive__group--info-active {
  opacity: 1 !important;
}


.arena-map__zone-back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  @include marginRight128();
  flex-shrink: 0;
  transition: 0.2s linear transform;

  @include at-xs {
    position: absolute;
    bottom: 60px;
  }

  @include at-md {
    position: relative;
    bottom: auto
  }

  &:hover {
    transform: translate(10px, -10px);

    .arena-map__zone-back-button-text {
      color: #A7FFFF;
    }

    .arena-map__zone-back-button-icon img {
      filter: contrast(50%) brightness(230%);
    }
  }

  &:active {
    .arena-map__zone-back-button-icon img {
      filter: contrast(50%) brightness(300%);
    }
  }
}

.arena-map__zone-back-button-icon {
  @include marginRight32();
  transition: 0.2s linear filter;
}

.arena-map__zone-back-button-text {
  font-weight: 300;
  @include font18();
  text-transform: uppercase;
  color: #FFFFFF;
  transition: 0.2s linear color;
}

.arena-map__zone-content {
  position: absolute;
  display: flex;
  left: 90px;
  right: 35px;
  top: auto;
  visibility: hidden;
}

.arena-map__zone-content-active {
  visibility: visible;
}

.arena-map__zone-wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.arena-map__zone {
  opacity: 0;
  position: absolute;
  /* transform: translateY(-50px); */
  transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
  //top: 13vh;
  bottom: 0;
  left: 25%;
  right: 35%;
  top: 0;
  @include marginTop128();
  @include paddingTop64();
  z-index: -1;

  @include at-xs {
    right: 0;
    left: 0;
    padding-right: 32px;
    padding-left: 32px;
    background: #000028;
  }

  @include at-md {
    left: 25%;
    right: 35%;
    padding: 0;
    background: transparent;
  }
}

.arena-map__zone-active {
  opacity: 1;
  transform: translateY(0);
  z-index: 1;
}

.arena-map__zone-top-content {
  transition: all 0.3s linear;
  position: relative;

  &.active {
    transform: translateX(-43vw);
    z-index: 1;

    & + .arena-map__zone-description-small {
      visibility: hidden;
    }
  }
}

.arena-map__zone-title {
  font-weight: bold;
  @include font48();
  color: #00FFFF;
  @include marginBottom16();
}

.arena-map__zone-description {
  @include font18();
  text-transform: uppercase;
  color: #00FFFF;
}

.arena-map__zone-description-small {
  @include marginTop96();
  @include font16();
  color: #FFFFFF;
}

.arena-map__zone-reserve-button {
  display: flex;
  align-items: center;
  text-decoration: none !important;
}

.arena-map__zone-reserve-button-icon {
  @include marginRight8();
}

.arena-map__zone-reserve-button-text {
  font-weight: 300;
  @include font18();
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none !important;
}

.arena-map__zone-slider {
  position: absolute;
  right: -50vw;
  top: 0;
  bottom: 0;
  width: 100vw;
  transition: right 0.5s ease-in-out;

  .arena-map__zone-back-button {
    position: absolute;
    bottom: 50%;
    left: 32px;
    margin-bottom: -36px;
    z-index: 2;
    display: none;

    @include at-xs {
      bottom: 60px;
    }

    @include at-md {
      bottom: 50%;
    }
  }

  @include at-md {
    width: 50vw;
  }

  .image-slider__navigation-wrapper {
    z-index: 2;
    left: auto;
    right: 0;
    bottom: 50%;
    margin-bottom: -32px;
  }

  &.active {
    right: 0;
    z-index: 1;

    @include at-md {
      z-index: auto;
    }

    .arena-map__zone-back-button {
      display: flex;

      @include at-md {
        display: none;
      }

      .arena-map__zone-back-button-text {
        display: none;

        @include at-sm {
          display: block;
        }
      }
    }

    .arena-map__zone-slider-images-legend--mobile {
      @include at-xs {
        display: flex;
      }

      @include at-md {
        display: none;
      }
    }

  }

  .image-slider {
    height: 100%;
    height: 100vh;

    .image-slider--slick, .slick-list, .slick-slide {
      height: 100vh;
    }

    .slick-slide {
      z-index: 0;
      position: relative;
    }

    .slick-active {
      z-index: 1;
    }

  }

  .arena-map__zone-slider-wrapper {
    position: relative;
    width: 100%;

    @include at-sm {
      height: 100%;
    }
  }

  .image-slide {
    height: 100vh;
    background-position: center;
    background-size: cover;
    max-height: 100vh;
  }

  .pageSliderLeft-enter, .pageSliderLeft-enter.pageSliderLeft-enter-active, .pageSliderLeft-exit, .pageSliderLeft-exit.pageSliderLeft-exit-active {
    position: absolute;
  }
}

.arena-map__zone-slider-images {
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  right: 0;
  transition: all 0.3s linear;

  @include at-xs {
    z-index: 1;
    right: -100vw;
  }

  @include at-md {
    right: -35vw;
    left: auto;

    .image-slider {
      opacity: 0.3;
    }
    &:hover {
      .image-slider {
        opacity: 0.4;
      }
    }
  }

  @include at-lg {
    z-index: 0;
  }

  &.active {
    right: 0;
    cursor: default;

    .image-slider {
      opacity: 1;
    }

    .arena-map__zone-slider-images-legend {
      display: none;
    }
  }
}

.arena-map__zone-slider-images-legend {
  display: none;
  align-items: center;
  position: absolute;
  top: 50vh;
  margin-top: -12px;

  @include at-md {
    display: flex;
    left: 1vw;
  }

  @include at-lg {
    left: 3vw;
  }
}

.arena-map__zone-slider-images-legend-icon {
  @include marginRight32();
}

.arena-map__zone-slider-images-legend-name {
  font-weight: 300;
  @include font18();
  text-transform: uppercase;
  color: #00FFFF;
}

.arena-map__zone-slider-images-legend--mobile {
  @include at-xs {
    display: none;
    left: 150px;
    z-index: 1;
    bottom: 60px;
    top: auto;
  }

  @include at-sm {
    left: 250px;
    bottom: 44px
  }

  @include at-md {
    display: none;
  }
}