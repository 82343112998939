@import '../base/mixins';

.arena-scales-block {
  position: relative;
  height: calc(2500 * 100vw / 1920);

  &.active {
    image {
      opacity: 0;
      pointer-events: none;
    }
    .arena-scales-block__item-mask {
      opacity: 0.1;
      pointer-events: none;
      cursor: default;
    }
    .arena-scales-block__item.active {
      image {
        transform: scale(1.2) translateX(-60%) translateY(-10%);
      }
    }
    .arena-scales-block__item--1, .arena-scales-block__item--2, .arena-scales-block__item--3, 
    .arena-scales-block__item--4, .arena-scales-block__item--5, .arena-scales-block__item--6 {
      z-index: 6;
    }

    .arena-scales-block__border {
      opacity: 0.5;
    }
  }
}

.arena-scales-block__border {
  opacity: 1;
  transition: 0.2s linear opacity;
}

.arena-scales-block__item {
  position: absolute;
  width: calc(750 * 100vw / 1920);
  pointer-events: none;
  transition: 0.2s linear opacity;

  image {
    transition: 0.2s linear all;
    transform: translateX(-50%);
  }

  &.secondary {
    opacity: 0.5;
    
  }
  &.active {
    transition: 0.5s linear opacity;
    
    image {
      transition: 0.5s linear transform, 0.5s linear opacity;
    }
  }
}

.arena-scales-block__item-mask {
  position: absolute;
  opacity: 0.5;
  transition: 0.2s linear all;
  pointer-events: auto;
  cursor: pointer;

  image {
    opacity: 1;
    transition: 0.2s linear all;
  }
  
  &:hover {
    opacity: 0;
  }
}

.arena-scales-block__item--1 {
  top: 0;
  right: calc(155 * 100vw / 1920);
  z-index: 3;
  transform: translateY(0px) scale(1);
}
.arena-scales-block__item--2 {
  top: calc(860 * 100vw / 1920);
  right: auto;
  left: 0;
  z-index: 3;

  @include at-sm {
    right: calc(775 * 100vw / 1920);
    left: auto;
  }
}
.arena-scales-block__item--3 {
  top: calc(1266 * 100vw / 1920);
  right: 0;
  z-index: 3;
}
.arena-scales-block__item--4 {
  top: calc(14 * 100vw / 1920);
  right: calc(575 * 100vw / 1920);
  z-index: 2;
}
.arena-scales-block__item--5 {
  right: 0;
  top: calc(305 * 100vw / 1920);
  z-index: 1;
}
.arena-scales-block__item--6 {
  right: calc(465 * 100vw / 1920);
  top: calc(877 * 100vw / 1920);
  z-index: 2;
}
.arena-scales-block__slider {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  transition: opacity 0.8s ease-in-out;
  opacity: 0;

  &.active {
    background: #000;
    right: 0;
    z-index: 6;
    opacity: 1;
  }

  .image-slider {
    height: 100%;
  }

  .image-slider--slick {
    .slick-track {
      height: 100vh;
    }
  }

  .image-slide {
    max-height: 100%;
    height: 100vh;
    background-position: center;
    position: relative;
  }

  .image-slider__navigation-wrapper {
    transform: translateX(0);
    z-index: 2;
    left: auto;
    right: 40px;
    bottom: 50%;
    margin-bottom: -32px;
  }

  .arena-map__zone-back-button {
    position: absolute;
    bottom: 120px;
    left: 32px;
    z-index: 2;

    .arena-map__zone-back-button-text {
      display: none;
    }

    @include at-sm {
      bottom: calc(50% - 52px);
      left: 40px;

      .arena-map__zone-back-button-text {
        display: block;
      }
    }
  }
}

.arena-scales-block__close-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
  display: none;

  @include at-md {
    display: block;
  }
}