@import "../base/mixins";

.back-block{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 1;

  @include at-xs{
    display: none;
  }

  @include at-md{
    display: block;
  }
}

.back-block__link{
  position: relative;
  z-index: 1;
  width: 120px;


  @include at-xs{
    left: 32px;
  }

  @include at-sm{
    left: 40px;
  }
}