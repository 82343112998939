@import '../base/mixins';

.arena-hardware {
  @include paddingBottom128();
}

.arena-hardware__logo {
  position: absolute;
  top: 20%;
  left: -20%;
  z-index: 1;
}

.arena-hardware__content-block {
  display: grid;
  grid-template-columns: 5fr 5fr 2fr;
  grid-gap: 40px;
  position: relative;

  @include at-xs {
    grid-template-columns: 1fr;

    .empty{
      display: none;
    }
  }

  @include at-sm {
    grid-template-columns: 5fr 5fr;
    padding-bottom: 48px;

    .empty{
      display: block;
    }
  }

  .arena-hardware__content-column:nth-of-type(2) {
    @include at-sm {
      margin-top: 300px;
    }
  }
}

.arena-hardware__content-column {}

.arena-hardware__item {
  margin-top: 100px;

  @include at-sm {
    margin-top: 150px;
  }

  @include at-md {
    margin-top: 200px;
  }

  @include at-lg {
    margin-top: 250px;
  }

  @include at-fh {
    margin-top: 300px;
  }
}

.arena-hardware__content-block--1 {
  .arena-hardware__preview-image {
    z-index: 1;
    right: calc(100vw - 235px);
    top: -20%;

    svg {
      height: calc(660 * 100vw / 1920);
      min-height: 245px;
    }

    @include at-sm {
      top: calc(-750 * 100vw / 1920);
      right: calc(-162 * 100vw / 1920);
    }
  }

  .arena-hardware__preview-text-wrapper {
    @include at-md {
      margin: 30% 20% 0 35%;
    }
  }
}

.arena-hardware__content-block--2 {
  .arena-hardware__preview-image {
    z-index: 0;
    right: -15px;
    top: -20%;    

    svg {
      height: calc(505 * 100vw / 1920);
      min-height: 206px;
    }

    @include at-sm {
      right: auto;
      top: calc(-1200 * 100vw / 1920);
    }
    @include at-md {
      top: calc(-975 * 100vw / 1920);
    }
  }
}

.arena-hardware__content-block--3 {
  .arena-hardware__preview-image {
    z-index: 0;
    right: -45px;
    top: -20%;

    svg {
      height: calc(460 * 100vw / 1920);
      min-height: 176px;
    }

    @include at-sm {
      right: calc(-155 * 100vw / 1920);
      top: calc(-900 * 100vw / 1920);
    }
  }
}

.arena-hardware__content-block--4 {
  .arena-hardware__preview-image {
    z-index: 2;
    right: calc(100vw - 240px);
    top: -20%;

    svg {
      height: calc(352 * 100vw / 1920);
      min-height: 122px;
    }

    @include at-sm {
      right: auto;
      top: calc(-1050 * 100vw / 1920);
    }
    @include at-lg {
      top: calc(-1200 * 100vw / 1920);
    }
    @include at-fh {
      top: calc(-1050 * 100vw / 1920);
    }
  }
}

.arena-hardware__content-block--5 {
  .arena-hardware__preview-image {
    z-index: 0;
    right: calc(100vw - 250px);
    top: -30%;

    svg {
      height: calc(462 * 100vw / 1920);
      min-height: 242px;
    }

    @include at-sm {
      right: calc(-150 * 100vw / 1920);
      top: calc(-880 * 100vw / 1920);
    }
  }
}

.arena-hardware__content-block--6 {
  .arena-hardware__preview-image {
    z-index: 2;
    right: -15px;
    top: -10%;

    svg {
      height: calc(172 * 100vw / 1920);
      min-height: 64px;
    }

    @include at-sm {
      right: auto;
      top: calc(-1200 * 100vw / 1920);
    }
    @include at-md {
      top: calc(-1650 * 100vw / 1920);
    }

    @include at-lg {
      top: calc(-1600 * 100vw / 1920);
    }
    @include at-fh {
      top: calc(-1300 * 100vw / 1920);
    }
  }
}

.arena-hardware__preview {
  min-width: 0;
  position: relative;
  pointer-events: none;
}

.arena-hardware__preview-image {
  position: absolute;

  img {
    height: 100%;
    width: auto;
  }

  svg {
    overflow: visible;
  }

  .preview-image--hover {
    transition: 0.5s ease-out transform;
    pointer-events: all;

    rect, path {
      transition: 0.2s linear opacity;
    }

    &:hover {
      transform: translateY(-10px);

      rect, path {
        opacity: 0;
      }
    }
  }
}

.arena-hardware__preview-image-mask {
  &:hover {
    opacity: 0;
  }
}

.arena-hardware__preview-text-wrapper {
  // margin-top: -300px;
  position: relative;
  z-index: 3;

  @include at-md {
    margin: 0 20% 0 35%;
  }
}

.arena-hardware__preview-text {
  @include marginBottom64();
}

.arena-hardware__preview-tag {
  font-size: 14px;
  @include marginBottom16();
  line-height: 1.71;
  letter-spacing: 0.680556px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.arena-hardware__preview-title {
  font-weight: bold;
  @include font32();
  color: #00FFFF;
}

.arena-hardware__preview-description {
  font-weight: 200;
  @include font32();
  color: #00FFFF;

  span {
    font-weight: bold;
  }
}