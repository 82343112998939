@import "../base/mixins";

.image-slider {
  grid-column-start: 1;
  grid-column-end: 6;
  width: 100%;
  height: calc((100vw - 80px) / 3);
  position: relative;
  overflow: hidden;

  @include at-xs {
    height: 50vh;
  }

  @include at-sm {
    height: calc((100vw - 80px) / 3);
  }

  .pageSliderLeft-enter,
  .pageSliderLeft-enter.pageSliderLeft-enter-active,
  .pageSliderLeft-exit,
  .pageSliderLeft-exit.pageSliderLeft-exit-active,
  .pageSliderRight-enter,
  .pageSliderRight-enter.pageSliderRight-enter-active,
  .pageSliderRight-exit,
  .pageSliderRight-exit.pageSliderRight-exit-active {
    position: absolute;
  }
}

.image-slide {
  width: 100%;
  height: 100%;
  max-height: calc((100vw - 80px) / 3);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;

  @include at-xs {
    max-height: 50vh;
  }

  @include at-sm {
    max-height: calc((100vw - 80px) / 3);
  }
}

.image-slider__navigation-wrapper {
  position: absolute;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.images-slider__navigation {
  width: 44px;
  height: 64px !important;
  border: 1px solid #00FFFF !important;
  border-right: 0 !important;
  border-left: 0 !important;
  color: #00FFFF;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;

  .base-button-transparent--decoration-right, .base-button-transparent--decoration-left {
    &:before, &:after {
      border-color: #00FFFF;
    }
  }

  .base-button-transparent--decoration-right:after {
    z-index: 1;
    width: 20%;
    height: 20%;
    bottom: -1px;
    right: -8.5px;
  }

  .base-button-transparent--decoration-right:before {
    right: -22px;
    z-index: 1;
  }

  .base-button-transparent--decoration-left:before {
    left: -18.5px;
    transform: skew(-17deg, 0deg);
    z-index: 1;
  }

  .base-button-transparent--decoration-left:after {
    width: 30%;
    bottom: 0px;
    left: -14px;
    transform: skew(5deg, 0deg);
    z-index: 1;
  }

  svg path {
    fill: #00FFFF;
  }
}

.images-slider__navigation--forward {
  margin-left: -10px;
  .base-button-transparent--decoration-left:after {
    left: -5px;
    transform: skew(15deg, 0deg);
  }
}

.images-slider__navigation--back {
  margin-right: -10px;
}

.images-slider__navigation-counter {
  font-weight: 400;
  color: #00FFFF;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.680556px;
}

.navigation-counter__current {
  font-weight: 500;
  opacity: 1;
}

.navigation-counter__all {
  opacity: 0.6;
}

.image-slide__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}