@import "../base/mixins";

.services-page {
  @include paddingTop256();
}

.service-page__container {
  position: relative;
}

.service-page__wrapper-grid {
  display: grid;
  @include paddingTop256();

  @include at-sm {
    grid-template-columns: 1fr 11fr 0fr;
    grid-gap: 32px;
  }

  @include at-lg {
    grid-template-columns: 1fr 10fr 1fr;
    grid-gap: 40px;
  }
}

.service-page__wrapper {
  background: white;
}

.service-page {

}

.service-page__preview {
  img {
    width: 100%;
    height: auto;
  }
}

.service-page__content-wrapper {
  display: grid;


  @include at-xs {
    grid-template-columns: 1fr 10fr 1fr;
  }

  @include at-md {
    grid-template-columns: 2fr 6fr 2fr;
  }
}

.service-page__content {
  color: #00043A;


}

.service-page__content-text {
  p, li {
    @include marginBottom24();
    @include font16();

    @include at-lg {
      width: calc(100% / 6 * 5);
    }
  }

  h4 {
    @include at-lg {
      width: calc(100% / 6 * 5);
    }
  }

  h2 {
    @include marginBottom24();
    @include font24();
  }

  h3 {
    @include marginBottom24();
    @include font16();
  }

  img {
    width: 100% !important;
    height: auto !important;
    @include marginTop80();
    @include marginBottom64();

    @include at-lg {
      margin-left: calc(((-100vw + 80px)/12));
      width: calc((100% + (100vw + 80px)/12*3) - 40px)!important;
    }
  }
}

.service-page__title {
  @include font48();
  @include marginTop80();
  @include marginBottom64();
}

.service-page__small-description {
  @include font32();
  @include marginBottom48();
}

.service-page__form-block {
  @include marginTop256();
  @include paddingBottom128();

  .event-page__title {
    padding-top: 0;
  }
}

.service-page__list-wrapper {
  @include marginTop128();
}

.service-page__back-button {
  cursor: pointer;
  text-align: right;
}

.service-page__back-button-wrapper {
  text-decoration: none !important;
  display: flex;

  @include at-sm {
    display: block;
  }
}

.service-page__back-button-icon {
  @include marginBottom24();
  @include marginRight24();

  @include at-sm {
    margin-right: 0;
  }
}

.service-page__back-button-text {
  font-weight: 300;
  @include font18();
  letter-spacing: 0.875px;
  text-align: right;
  text-transform: uppercase;
  color: #FFFFFF;
}